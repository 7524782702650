import { create } from "zustand";
import { ParanetConnection } from "../servers";
import { appDatabase } from "../database/database";
import { ParanetService } from "../services/paranet";

interface ParanetConnectionsStoreActions {
  initialize: () => Promise<void>;
  add: (connection: ParanetConnection) => void;
  remove: (serverName: string) => void;
  list: () => ParanetConnection[];
  get: (serverName: string) => ParanetConnection | undefined;
}

interface ParanetConnectionsStoreState {
  connections: Map<string, ParanetConnection>;
}

const initialState: ParanetConnectionsStoreState = {
  connections: new Map(),
};

export const useParanetConnectionsStore = create<
  ParanetConnectionsStoreState & ParanetConnectionsStoreActions
>()((set, get) => ({
  ...initialState,
  initialize: async () => {
    if (get().connections.size > 0) {
      return;
    }

    const localServers = new Map<string, ParanetConnection>();

    const mergedServers = new Map([
      ...initialState.connections,
      ...localServers,
    ]);

    try {
      const paranetConnections = await ParanetService.getConnections();
      for (const paranetConnection of paranetConnections) {
        mergedServers.set(paranetConnection.server.name, paranetConnection);
      }
    } catch (error) {
      console.log("error", error);
    }

    set({ connections: mergedServers });
  },
  add: (connection: ParanetConnection) => {
    set(({ connections }) => {
      connections.set(connection.server.name, connection);
      appDatabase.writeServer(connection.server);
      return connections;
    });
  },
  remove: (serverName: string) => {
    set(({ connections }) => {
      connections.delete(serverName);
      appDatabase.deleteServer(serverName);
      return connections;
    });
  },
  list: () => Array.from(get().connections.values()),
  get: (serverName: string) => get().connections.get(serverName),
}));
