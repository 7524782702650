import { ParanetClient } from "paranet-client-ts";
import { ParanetRepository } from "../../repositories";
import { ConnectionState, ParanetConnection } from "../../servers";
import UserStorage from "../../utils/user.storage";
import ReconnectingWebSocket from "reconnecting-websocket";
import { getMemberName } from "../../utils/conversation.utils";

export class ParanetService {
  public static async getConnections(): Promise<ParanetConnection[]> {
    const token = UserStorage.get()?.accessToken || "";
    const result = await ParanetRepository.getConnections(token);
    return result;
  }

  public static async addConnection(
    paranet: ParanetConnection
  ): Promise<ParanetConnection> {
    const token = UserStorage.get()?.accessToken || "";
    return await ParanetRepository.addConnection(paranet, token);
  }

  public static async login(
    paranet: ParanetConnection,
    loginId: string,
    password: string
  ): Promise<ParanetConnection> {
    const token = UserStorage.get()?.accessToken || "";
    try {
      await ParanetRepository.login(paranet, loginId, password);
      await ParanetRepository.updateConnection(paranet, token);
    } catch (error: any) {
      if (error?.response?.data?.error) {
        throw Error(error.response.data.error);
      }
      throw Error("Login failed!");
    }

    return paranet;
  }

  static async checkStoredLogin(paranet: ParanetConnection): Promise<boolean> {
    if (!paranet) return false;

    try {
      const { loginId, token, refresh } = paranet;
      const options = {
        serviceEndpoint: paranet.server.url,
        actorId: loginId || "",
      };

      const client = new ParanetClient(options);

      client.setTokens(token || "", refresh || "");

      if (!client.isTokenValid()) return false;

      paranet.loginId = `${loginId}@1.0.0`;
      paranet.token = token;
      paranet.refresh = refresh;
      console.log(`Loaded stored ${paranet.server.name} credentials`);
      return true;
    } catch (err) {
      /* empty */
      return false;
    }
  }

  static isLocal(paranet: ParanetConnection): boolean {
    return paranet.server.name === "local";
  }

  static connect(
    paranet: ParanetConnection,
    callback: (name: string, status: ConnectionState) => void
  ) {
    if (paranet.webSocket) {
      if (paranet.connected === "pending" || paranet.connected === "connected")
        return;
      else ParanetService.disconnect(paranet);
    }

    paranet.connected = "pending";

    const webSocket = new ReconnectingWebSocket(
      `${paranet.server.wsUrl}/agent/${getMemberName(paranet.loginId)}`,
      undefined,
      { maxRetries: 2 }
    );
    webSocket.onopen = () => {
      const initMsg = {
        type: "Init",
        body: paranet.token,
      };
      const obsMsg1 = {
        type: "InitObserver",
        body: {
          id: paranet.loginId,
          observance: {
            sender: paranet.loginId,
          },
        },
      };
      const obsMsg2 = {
        type: "InitObserver",
        body: {
          id: paranet.loginId,
          observance: {
            recipient: paranet.loginId,
          },
        },
      };

      webSocket.send(JSON.stringify(initMsg));
      webSocket.send(JSON.stringify(obsMsg1));
      webSocket.send(JSON.stringify(obsMsg2));
      if (paranet.wsHandler) webSocket.onmessage = paranet.wsHandler;
      paranet.connected = "connected";
      callback(paranet.server.name, "connected");

      return false;
    };
    webSocket.onerror = console.error;
    webSocket.onclose = (ev) => {
      callback(paranet.server.name, "disconnected");
    };
    paranet.webSocket = webSocket;
  }

  static setHandler(
    paranet: ParanetConnection,
    handler: (evt: MessageEvent) => void
  ) {
    paranet.wsHandler = handler;
    if (paranet.webSocket) paranet.webSocket.onmessage = handler;
  }

  static disconnect(paranetConnection: ParanetConnection) {
    paranetConnection.webSocket?.close();
    paranetConnection.webSocket = undefined;
    paranetConnection.connected = "disconnected";
  }
}
