import { appDatabase, IThreadState } from "../database/database";
import {
  Conversation,
  LoadReq,
  MessageData,
  RequestMessage,
  ThreadAction,
  ThreadTreeAction,
} from "../entities";
import { Thread } from "../entities/paranet/thread/Thread";
import { ParanetConnection } from "../servers";
import { markThreadAsRead, toEntries, toThread } from "../utils";
import { isConversationMine } from "../utils/conversation.utils";
import { loadConversation } from "./conversations.service";

export class ThreadLoader {
  private backlog: Record<string, LoadReq>;

  private pending?: LoadReq;

  public callback: (name: string, th: Thread) => void;

  constructor() {
    this.backlog = {};
    this.callback = (_) => {};
  }

  load(pn: ParanetConnection, id: string) {
    if (id !== this.pending?.id && !this.backlog[id]) {
      this.backlog[id] = { pn, id };
      this.next();
    }
  }

  private next() {
    const todo = Object.values(this.backlog);
    if (!this.pending && todo.length > 0) {
      const req = todo[0];
      this.pending = req;
      delete this.backlog[req.id];
      this.doLoad(req).then(this.complete.bind(this));
    }
  }

  private async doLoad(req: LoadReq) {
    const conv = await loadConversation(req.pn, req.id);
    const state = await appDatabase.getThreadState(conv.id);
    this.callback(req.pn.server.name, toThread(conv, state));
    return true;
  }

  private complete(okay: boolean) {
    if (okay && this.pending) {
      this.pending = undefined;
      this.next();
    }
  }

  public async fetchThread(
    id: string,
    paranet: ParanetConnection,
    actorsSelection: string | string[],
    updateConvTree: React.Dispatch<ThreadTreeAction>,
    updateThreads: React.Dispatch<ThreadAction>
  ) {
    const conv = await loadConversation(paranet, id);
    const actorId = paranet.loginId || "guest@1.0.0";

    // observation returns child conversations
    if (isConversationMine(actorId, conv)) {
      const state = await appDatabase.getThreadState(conv.id);
      const thread = toThread(conv, state);
      updateConvTree({
        action: "append",
        threadList: [thread],
        actorId,
      });
      updateThreads({ action: "append", thread });
      if (Array.isArray(actorsSelection) && actorsSelection[1] === id) {
        markThreadAsRead(actorId, toThread(conv)); // update with count and lastUpdated
        const requestedEntries = toEntries(conv.messages || []);
        for (const entry of requestedEntries) {
          const actionData = (entry.contents.data as RequestMessage).data;
          const inputSchemaRef = actionData?.input_schema_ref;
          if (!inputSchemaRef || !actionData) {
            continue;
          }
          for (const [key, value] of Object.entries(inputSchemaRef)) {
            if (value !== "paranet:document_ref" || !actionData.data) {
              continue;
            }

            const messageDataProp = (actionData.data as MessageData)[
              key as keyof MessageData
            ];
            if (
              messageDataProp &&
              typeof messageDataProp !== "string" &&
              messageDataProp.id
            ) {
              const imageId = messageDataProp.id;

              const url = paranet.server.url + "/document/fetch/" + imageId;

              const serverName = paranet.server.name;
              const localLogins = await appDatabase.getLogin(serverName);

              const config = {
                headers: {
                  authorization: `Bearer ${localLogins?.token}`,
                },
              };

              const response = await fetch(url, config);
              const imageContent = await response.blob();
              if (key === "image") {
                messageDataProp.imageContent = imageContent;
              }
            }
          }
        }
        return requestedEntries;
      }
    }
  }

  public async fillThreads(
    convList: Conversation[],
    paranet: ParanetConnection,
    updateThreads: React.Dispatch<ThreadAction>
  ) {
    const convPromises: Promise<{
      conversation: Conversation;
      threadState: IThreadState | undefined;
    }>[] = [];
    for (const conv of convList) {
      const promise = async () => {
        const conversation = await loadConversation(paranet, conv.id);
        const threadState = await appDatabase.getThreadState(conversation.id);

        return { conversation, threadState };
      };

      convPromises.push(promise());

      const result = await Promise.allSettled(convPromises);
      result.forEach((r) => {
        if (r.status === "rejected") return;

        updateThreads({
          action: "append",
          thread: toThread(r.value.conversation, r.value.threadState),
        });
      });
    }
  }
}
