import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ParanetConnection } from "../../servers";

import styles from "./ParanetListSideBar.module.scss";

import { useParanetConnectionsStore } from "../../store/useParanetConnections.store";
import classNames from "classnames/bind";
import SettingsPopup from "./SettingsPopup/SettingsPopup";

const classes = classNames.bind(styles);

interface ParanetListTypes {
  activeParanet?: ParanetConnection;
  onRequestLogout: () => void;
  onRequestAddNewServer: () => void;
  onSelectParanet: (paranetId: string) => void;
}

const ParanetListSideBar = ({
  activeParanet,
  onSelectParanet,
  onRequestLogout,
  onRequestAddNewServer,
}: ParanetListTypes) => {
  const paranets = useParanetConnectionsStore((store) => store.list());

  const getConnectionColor = (paranetName: string) => {
    if (!activeParanet) {
      return "gray";
    }

    if (activeParanet.server.name === paranetName) {
      if (activeParanet.connected === "connected") return "green";

      return activeParanet.connected === "pending" ? "yellow" : "red";
    }

    return "gray";
  };

  return (
    <div className={classes(styles.sidebar, styles.expanded)}>
      <div className={styles.list}>
        {paranets.map((paranet) => (
          <div
            key={paranet.server.name}
            className={classes(styles.item, styles.serverItem, {
              selected: activeParanet?.server.name === paranet.server.name,
            })}
            onClick={() => onSelectParanet(paranet.server.name)}
          >
            <div>
              <FontAwesomeIcon
                width={12}
                icon={faCircle}
                title={paranet.connected}
                className={styles.connectionStatus}
                color={getConnectionColor(paranet.server.name)}
              />
            </div>
            <div className={styles.icon} title={paranet.server.name}>
              {paranet.server.name[0].toUpperCase()}
            </div>
          </div>
        ))}
        <SettingsPopup
          activeParanet={activeParanet}
          onRequestLogout={onRequestLogout}
          onRequestAddNewServer={onRequestAddNewServer}
        />
      </div>
    </div>
  );
};

export default ParanetListSideBar;
