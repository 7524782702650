import { ParanetConnection } from "../../../servers";

import styles from "./ORAList.module.scss";
import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const classes = classNames.bind(styles);

interface ORAListTypes {
  loadingORAs?: boolean;
  selectedParanet?: ParanetConnection;
}

const oras = [{ id: "sample-ora", title: "Sample ORA #1" }];

const ORAList = ({ loadingORAs, selectedParanet }: ORAListTypes) => {
  const navigate = useNavigate();

  if (!selectedParanet) {
    return (
      <div className={styles.container}>
        Click on the cog icon and connect to a paranet to list your ORAs
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {loadingORAs ? (
        <div>Loading...</div>
      ) : (
        <div className={styles.content}>
          <div className={styles.listWrapper}>
            {oras.map((ora, i) => (
              <div
                key={i}
                className={classes(styles.ora, {
                  selectedOra: location.pathname.endsWith(ora.id),
                })}
                onClick={() => navigate(`/oras/${ora.id}`)}
              >
                <span>{ora.title}</span>
                <div className={styles.rightAdornments}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            ))}
          </div>
          <div className={styles.actionsWrapper}>
            <button className={styles.btnNewORA}>
              <FontAwesomeIcon icon={faPlusSquare} color="#03396c" /> Create New
              ORA
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ORAList;
