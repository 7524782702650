import { CognitoLoginResult } from "../entities";

const storageKey = "@paracord-user";

export default class UserStorage {
  static hasToken = (): boolean => {
    const token = localStorage.getItem(storageKey);
    return !!token;
  };

  static logout = () => {
    localStorage.removeItem(storageKey);
  };

  static set = (result: CognitoLoginResult) => {
    localStorage.setItem(storageKey, JSON.stringify(result));
  };

  static get = (): CognitoLoginResult | null => {
    const data = localStorage.getItem(storageKey);
    if (!data) return null;
    return JSON.parse(data);
  };
}
