import { Button, Modal } from "react-bootstrap";
import RjsfForm, { IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { useEffect, useState } from "react";
import { SkillAction, uiSchema } from "../../../entities";
import { useParanetContext } from "../../../hooks/useParanetContext";
import { Skillset } from "../../../entities/paranet/skillset/Skillset";
import { isEmptySchema, requestSchema } from "../../../utils/schema.utils";
import { MessageSchema } from "../../../entities/schema/MessageSchema";
import axios from "axios";
import { appDatabase } from "../../../database/database";
import { createModalCard } from "../../../utils/adaptiveCards.utils";
import { AdaptiveCard } from "../../../adaptive-card";
import { identifierToTitle } from "../../../utils/utility";

interface ActiveRequest {
  actorId: string;
  subject: string;
  action: SkillAction;
  schema: MessageSchema;
}

interface NewNodeModalTypes {
  skill: string;
  onClose: () => void;
  onAddNode: (data: Record<string, string | object> | undefined) => void;
}

const AddNewNodeModal = ({ skill, onClose, onAddNode }: NewNodeModalTypes) => {
  const modalCardInst = createModalCard();

  const { actors, selectedParanet } = useParanetContext();

  const [isLoading, setIsLoading] = useState(false);
  const [skillId, actionId, subjectId] = skill.split("|");
  const [skillsets, setSkillsets] = useState<Record<string, Skillset>>({});
  const [activeRequest, setActiveRequest] = useState<
    ActiveRequest | undefined
  >();
  const [modalFormData, setModalFormData] = useState<
    Record<string, string | object> | undefined
  >();

  useEffect(() => {
    void fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skill]);

  const onChangeFormData = (data: IChangeEvent, _id?: string) => {
    const { formData } = data;
    setModalFormData(formData);
  };

  const fetchSkills = async () => {
    if (!skill || !actors || !selectedParanet) {
      return;
    }

    const actor = actors.find((a) => a.entityId === skillId);
    if (!actor) {
      console.error("Actor not found");
      return;
    }

    const skillsetPromises: Promise<Skillset>[] = [];
    actor.skillSets.forEach((ss) =>
      skillsetPromises.push(selectedParanet.skillsetDB.getSkillset(ss.entityId))
    );
    const results = await Promise.allSettled(skillsetPromises);
    for (let i = 0; i < actor.skillSets.length; i++) {
      const promiseResult = results[i];
      const currSkillSet = actor.skillSets[i];
      if (promiseResult.status === "rejected") {
        continue;
      }

      skillsets[currSkillSet.entityId] = promiseResult.value;
    }

    setSkillsets({ ...skillsets });

    void newRequest();
  };

  const newRequest = () => {
    if (!skill) {
      return;
    }

    if (skill === "-1") {
      setModalFormData(undefined);
      setActiveRequest(undefined);
      return;
    }

    const skillSubject = skillsets[skillId].skills.find(
      (sk) => sk.subject === subjectId
    );
    const action = skillSubject?.actions.find((a) => a.action === actionId);
    if (!action) {
      return;
    }

    setIsLoading(true);
    requestSchema(action).then((schema) => {
      setModalFormData(undefined);
      setActiveRequest({
        action,
        schema,
        subject: subjectId,
        actorId: actors?.find((a) => a.entityId === skillId)?.entityId || "",
      });
      setIsLoading(false);
    });
  };

  return (
    <Modal size="lg" show onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Node - {identifierToTitle(actionId)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <div>Loading...</div>}
        {activeRequest &&
          isEmptySchema(activeRequest.schema) &&
          "No params available"}
        {activeRequest &&
          !isEmptySchema(activeRequest.schema) &&
          activeRequest.schema.type === "object" && (
            <RjsfForm
              uiSchema={uiSchema}
              validator={validator}
              formData={modalFormData}
              schema={activeRequest.schema}
              onChange={onChangeFormData}
            />
          )}
        {activeRequest?.schema.type === "AdaptiveCard" && (
          <AdaptiveCard card={modalCardInst} payload={activeRequest.schema} />
        )}
        {selectedParanet && activeRequest?.schema.type === "documentRef" && (
          <>
            <input
              type="file"
              onChange={async (event) => {
                if (!event.target.files) return;

                const url = selectedParanet.server.url + "/document/upload";

                const file = event.target.files[0];
                const formData = new FormData();
                formData.append("file", file);

                const serverName = selectedParanet.server.name;
                const localLogins = await appDatabase.getLogin(serverName);
                const config = {
                  headers: {
                    authorization: `Bearer ${localLogins?.token}`,
                    "X-ACTOR-ID": "guest",
                  },
                };

                setIsLoading(true);
                axios
                  .post(url, formData, config)
                  .then((response) => {
                    if (response.status === 200) {
                      const data = {
                        image: {
                          id: response.data.id,
                          contentType: response.data.contentType,
                        },
                      };
                      setModalFormData(data);
                    }
                  })
                  .finally(() => setIsLoading(false));
              }}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onAddNode(modalFormData)}>
          Add Node
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewNodeModal;
