import { RJSFSchema } from "@rjsf/utils";
import { useParanetConnectionsStore } from "../store/useParanetConnections.store";
import { getLastConnectedParanet } from "../services/localStorage.service";

export class SchemaDB {
  public endpoint: string = "";
  public paranetName: string = "";

  public schemas: { [key: string]: RJSFSchema } = {};

  public constructor() {
    const paranetName = (this.paranetName = getLastConnectedParanet() || "");
    const activeParanet = useParanetConnectionsStore
      .getState()
      .connections.get(paranetName);
    const url = activeParanet?.server.url || "";
    this.updateEndpoint(paranetName, url);
  }

  public updateEndpoint(paranetName: string, endpoint: string) {
    this.schemas = {};
    this.endpoint = endpoint;
    this.paranetName = paranetName;
  }

  public async getSchema(ref: string): Promise<RJSFSchema> {
    if (!this.schemas[ref]) {
      console.log("GET Schema", ref);
      await this.fetchSchema(ref);
      console.log(this.schemas[ref]);
    }
    if (this.schemas[ref]) {
      return this.schemas[ref];
    }
    throw new Error(`Failed to fetch schema ${ref}`);
  }

  public async fetchSchema(ref: string) {
    const url = `${this.endpoint}/schema/${ref}`;
    const activeParanet = useParanetConnectionsStore
      .getState()
      .connections.get(this.paranetName);
    if (!activeParanet) {
      return;
    }

    if (activeParanet.connected === "pending") {
      await activeParanet.refreshAuth();
    }

    const headers: Record<string, string> = {
      "Content-Type": "application/json",
      "X-ACTOR-ID": activeParanet.loginId!,
    };
    if (activeParanet.server.name !== "local")
      headers.Authorization = `Bearer ${activeParanet.token}`;
    const resp: RJSFSchema = await fetch(url, { headers }).then((result) =>
      result.json()
    );
    if (resp.type && resp.type === "object" && resp.properties) {
      for (const key in resp.properties) {
        const fieldSchema = resp.properties[key];
        if (typeof fieldSchema === "object") {
          if (fieldSchema.$ref) {
            const refSchema = await this.getSchema(fieldSchema.$ref);
            resp.properties[key] = this.stripSchema(refSchema);
          }
        }
      }
    }

    this.schemas[ref] = resp;
  }

  public stripSchema(schema: RJSFSchema) {
    return {
      type: schema.type,
      const: schema.const,
      enum: schema.enum,
      default: schema.default,
      properties: schema.properties,
      required: schema.required,
    };
  }
}
