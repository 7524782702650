import { useOutletContext } from "react-router";
import { Actor } from "../entities/paranet/actor/Actor";
import { ParanetConnection } from "../servers";
import { Thread } from "../entities/paranet/thread/Thread";

export type ParanetContext = {
  actor?: Actor;
  actors?: Actor[];
  threads?: Thread[];
  selectedParanet?: ParanetConnection;
};

export const useParanetContext = () => {
  return useOutletContext<ParanetContext>();
};
