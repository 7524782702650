import { Reducer, useEffect, useReducer, useState, useRef } from "react";
import { Outlet, useParams } from "react-router";
import { listActorConversations } from "./services/conversations.service";

import * as serverUtils from "./servers";

import DebugPanel from "./layout/DebugPanel/DebugPanel";
import { useParanetConnectionsStore } from "./store/useParanetConnections.store";
import { ParanetConnection } from "./servers";
import { getMemberName } from "./utils/conversation.utils";
import {
  Actor,
  BrokerMessage,
  Entry,
  IVersions,
  ServerState,
  Thread,
  ThreadAction,
  ThreadTree,
  ThreadTreeAction,
} from "./entities";
import {
  initThreadList,
  isThreadVisibleInTree,
  threadTreeReducer,
  threadsReducer,
} from "./utils/thread.utils";
import SideBar from "./layout/SideBar/SideBar";
import MainContent from "./layout/MainContainer";
import LoginModal from "./components/LoginModal/LoginModal";
import AddNewServerModal from "./components/AddNewServerModal/AddNewServerModal";

import {
  getLastConnectedParanet,
  setLastConnectedParanet,
} from "./services/localStorage.service";

import { ThreadLoader } from "./services";
import { ParanetContext } from "./hooks/useParanetContext";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./App.module.scss";
import { ParanetService } from "./services/paranet";
import UserStorage from "./utils/user.storage";
import settings from "./settings";

declare global {
  interface Window {
    versions?: IVersions;
  }
}

const treeThreadLoader = new ThreadLoader();

const App = () => {
  const navigate = useNavigate();

  const { actorId: actorIdParam } = useParams();

  const [selectedParanet, setSelectedParanet] = useState<ParanetConnection>();
  const [paranetServers, getParanetServer] = useParanetConnectionsStore(
    (state) => [state.connections, state.get]
  );
  const [serverState, setServerState] = useState<ServerState>("init");
  const [showNewServerModal, setShowNewServerModal] = useState(false);
  const [debugOpen, setDebugOpen] = useState(false);
  const [debugRefresh, setDebugRefresh] = useState(0);
  const [actorsSelection, setActorsSelection] = useState<string | string[]>("");
  const [actors, setActors] = useState<Actor[]>([]);
  const [fetchingActors, setFetchingActors] = useState(false);
  const [loadingActorData, setLoadingActorData] = useState(false);
  const [loadingConversationTree, setLoadingConversationTree] = useState(false);
  const [conversationTree, updateConvTree] = useReducer<
    Reducer<ThreadTree, ThreadTreeAction>
  >(threadTreeReducer, {});
  const [threads, updateThreads] = useReducer<Reducer<Thread[], ThreadAction>>(
    threadsReducer,
    []
  );
  const location = useLocation();
  const contentBottom = useRef<HTMLDivElement>(null);

  const [, setSkills] = useState<string[]>([]);
  const [entries, setEntries] = useState<Entry[]>([]);
  const [context, setContext] = useState<ParanetContext>({});

  useEffect(() => {
    console.log("REACT_APP_AUTH_SERVICE_URI", settings.env.AUTH_SERVICE_URI);
    if (!UserStorage.hasToken()) navigate("/auth/cognito");
  }, []);

  useEffect(() => {
    if (selectedParanet) {
      return;
    }

    const initServers = async () => {
      //await initializeServers();
      const defaultParanet = getParanetServer(
        getLastConnectedParanet() || "local"
      );

      if (!defaultParanet) {
        console.warn("There's no default paranet set");
        return;
      }

      selectParanet(defaultParanet.server.name);
    };

    initServers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paranetServers]);

  useEffect(() => {
    if (!selectedParanet) {
      return;
    }

    const checkLogin = async () => {
      setServerState("pre-login");
      const isLocal = ParanetService.isLocal(selectedParanet);
      const isLoged = await ParanetService.checkStoredLogin(selectedParanet);
      if (isLocal || isLoged) {
        setServerState("load");
      } else {
        setServerState("login");
      }
    };

    if (serverState === "init") {
      checkLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverState, selectedParanet]);

  useEffect(() => {
    if (
      typeof actorsSelection !== "string" &&
      entries.length > 0 &&
      contentBottom.current
    ) {
      contentBottom.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [entries.length, actorsSelection]);

  useEffect(() => {
    setContext({
      ...context,
      threads,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threads]);

  useEffect(() => {
    if (!selectedParanet) {
      return;
    }

    const fetchActors = async () => {
      const actorId = selectedParanet.loginId || "guest@1.0.0";
      const paranetActors = await serverUtils.loadActorsFromParanet(
        selectedParanet.server.name
      );
      const data = paranetActors.filter((a) => a.entityId !== actorId);
      setActors(data);
      setContext({
        ...context,
        actors: data,
      });
    };

    const fetchData = async () => {
      const actorId = selectedParanet.loginId || "guest@1.0.0";
      const data = await listActorConversations(selectedParanet, actorId);
      const threadList = await initThreadList(data);
      updateConvTree({ action: "reset", threadList, actorId });
      updateThreads({ action: "reset", threadList });
    };

    if (!fetchingActors && serverState === "load") {
      setFetchingActors(true);
      fetchActors()
        .catch(console.error)
        .finally(() => {
          setFetchingActors(false);
        });
    }

    if (!loadingConversationTree && serverState === "load") {
      console.log("loading...");
      setLoadingConversationTree(true);
      fetchData()
        .catch(console.error)
        .finally(() => {
          console.log("finally");
          setLoadingConversationTree(false);
          setServerState("ready");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParanet, serverState]);

  // Load threads shown in the conversation tree to provide summary title
  useEffect(() => {
    if (!selectedParanet) {
      return;
    }

    treeThreadLoader.callback = (net: string, th: Thread) => {
      if (selectedParanet.server.name === net) {
        const actorId = selectedParanet.loginId || "guest@1.0.0";
        updateConvTree({
          action: "append",
          threadList: [th],
          actorId,
        });
      }
    };
    for (const actor in conversationTree) {
      conversationTree[actor]
        .filter((conv) => !conv.messages && isThreadVisibleInTree(conv))
        .forEach((conv) => treeThreadLoader.load(selectedParanet, conv.id));
    }
  }, [selectedParanet, conversationTree]);

  useEffect(() => {
    if (!selectedParanet) {
      return;
    }

    if (
      serverState === "ready" &&
      selectedParanet.connected === "disconnected"
    ) {
      ParanetService.connect(selectedParanet, (name, status) => {
        selectedParanet.setLogoutHandler(() => setServerState("init"));
        setLastConnectedParanet(name);
        setSelectedParanet(selectedParanet);
      });
    }
  }, [selectedParanet, serverState]);

  useEffect(() => {
    if (!selectedParanet) {
      return;
    }

    ParanetService.setHandler(selectedParanet, (evt: MessageEvent) => {
      try {
        const msg = JSON.parse(evt.data);
        console.dir(msg);
        if (msg.type === "Observation") {
          const obs = msg as BrokerMessage;
          treeThreadLoader
            .fetchThread(
              obs.body.msg.conversation,
              selectedParanet,
              actorsSelection,
              updateConvTree,
              updateThreads
            )
            .then((requestedEntries) => {
              if (requestedEntries) setEntries(requestedEntries);
            })
            .catch(console.error);
        }
      } catch (err) {
        console.error(err);
      }

      return false;
    });
  }, [selectedParanet, actorsSelection]);

  useEffect(() => {
    if (!actors || actors.length <= 0) {
      return;
    }

    if (actorIdParam) {
      selectActor(getMemberName(actorIdParam));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actorIdParam, actors]);

  const selectActor = (name: string) => {
    if (!selectedParanet) {
      return;
    }

    setLoadingActorData(true);

    updateThreads({
      action: "reset",
      threadList: conversationTree[name] || [],
    });

    const actor = actors.find((a) => a.entityId.startsWith(name));
    setContext({
      ...context,
      actor,
      selectedParanet,
    });

    if (conversationTree[name]) {
      treeThreadLoader
        .fillThreads(conversationTree[name], selectedParanet, updateThreads)
        .catch(console.error)
        .finally(() => setLoadingActorData(false));
    } else {
      setLoadingActorData(false);
    }

    if (actor) {
      setActorsSelection(name);
    }
  };

  const selectParanet = (name: string) => {
    if (name === selectedParanet?.server.name) {
      return;
    }

    const pn = getParanetServer(name);
    if (!pn) {
      console.warn(`Paranet ${name} is unavailable`);
      return;
    }
    const actorId = pn.loginId || "guest@1.0.0";

    setActorsSelection("");
    setActors([]);
    setSkills([]);
    updateConvTree({ action: "reset", threadList: [], actorId });
    setFetchingActors(false);
    setLoadingConversationTree(false);
    setDebugOpen(false);
    setSelectedParanet(pn);
    setContext({
      ...context,
      threads: [],
      actor: undefined,
      actors: undefined,
      selectedParanet: pn,
    });
    setServerState("init");
  };

  const logoutFromParanet = () => {
    if (!selectedParanet) {
      return;
    }

    ParanetService.disconnect(selectedParanet);
    setActors([]);
    setSkills([]);
    setActorsSelection("");
    updateConvTree({ action: "reset", threadList: [], actorId: "" });
    setSelectedParanet(undefined);
    setContext({
      ...context,
      threads: [],
      actor: undefined,
      actors: undefined,
      selectedParanet: undefined,
    });

    setServerState("init");
  };

  const MainView = () => {
    if (loadingActorData) {
      return <div style={{ color: "#000" }}>Loading...</div>;
    }

    if (location.pathname === "/actors" && !actorsSelection) {
      return (
        <div style={{ color: "#000" }}>
          Select an actor to see available actions
        </div>
      );
    }

    return <Outlet context={context} />;
  };

  return (
    <div className={styles.main}>
      <SideBar
        actors={actors}
        threadTree={conversationTree}
        loadingActors={fetchingActors}
        onSelectParanet={selectParanet}
        actorsSelection={actorsSelection}
        selectedParanet={selectedParanet}
        onRequestLogout={logoutFromParanet}
        onRequestAddNewServer={() => setShowNewServerModal(true)}
      />

      <MainContent>
        <div
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 1,
              height: "100%",
              display: "flex",
              overflowY: "scroll",
              flexDirection: "column",
              padding: "10px 20px 20px",
              backgroundColor: "#b3cde0",
            }}
          >
            <MainView />

            <div ref={contentBottom} />
          </div>
          {selectedParanet?.server.debug && (
            <DebugPanel
              debugOpen={debugOpen}
              count={entries.length}
              debugRefresh={debugRefresh}
              onTogglePanel={setDebugOpen}
              actorsSelection={actorsSelection}
              onRefreshUpdate={setDebugRefresh}
            />
          )}
        </div>
      </MainContent>

      {serverState === "login" && selectedParanet && (
        <LoginModal
          setServerState={setServerState}
          selectedParanet={selectedParanet}
          setParanet={setSelectedParanet}
        />
      )}

      {showNewServerModal && (
        <AddNewServerModal onCloseModal={() => setShowNewServerModal(false)} />
      )}
    </div>
  );
};

export default App;
