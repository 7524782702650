import axios from "axios";

export class AuthClient {
  public static async makeRequest(
    url: string,
    method = "GET",
    data = {},
    aditionalHeaders: any = {}
  ) {
    const headers: any = {};
    headers["Accept"] = "application/json";
    headers["Content-Type"] = "application/json";

    for (const idx in aditionalHeaders) {
      headers[idx] = aditionalHeaders[idx];
    }

    const requestConfig = {
      url,
      data,
      method,
      headers,
      crossdomain: true,
    };

    const response = await axios(requestConfig);

    return response.data;
  }
}
