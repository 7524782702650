import { Handle, Position } from "reactflow";

import styles from "./ExitNode.module.scss";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExitNode = () => {
  return (
    <div className={styles.node}>
      <div className={styles.content}>
        <div className={styles.handleWrapper}>
          <Handle type="target" position={Position.Left} />
        </div>
        <FontAwesomeIcon icon={faPowerOff} />
      </div>
      <div className={styles.label}>Exit Point</div>
    </div>
  );
};

export default ExitNode;
