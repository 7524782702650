// Persistent store for server logins and thread read/unread state.
// Supports browser and Electron

import { openDB, IDBPDatabase, DBSchema } from "idb";
import { ParanetServer } from "../entities/paranet/ParanetServer";

export interface ILoginRecord {
  name: string;
  loginId: string;
  token: string;
  refresh: string;
}

export interface IORARecord {
  id: string;
  name: string;
  jsonData: string;
}

export interface IThreadState {
  actor: string;
  cid: string;
  viewCount: number;
  lastUpdated: string;
}

interface AppSchema extends DBSchema {
  oras: {
    key: string;
    value: IORARecord;
    indexes: { "by-name": string };
  };
  servers: {
    key: string;
    value: ParanetServer;
    indexes: { "by-name": string };
  };
  logins: {
    key: string;
    value: ILoginRecord;
    indexes: { "by-name": string };
  };
  threads: {
    key: string;
    value: IThreadState;
    indexes: { "by-actor": string };
  };
}

class AppDB {
  private static DbVersion = 2;
  private db: IDBPDatabase<AppSchema>;

  constructor(db: IDBPDatabase<AppSchema>) {
    this.db = db;
  }

  static async open() {
    const database = await openDB<AppSchema>("paracordData", this.DbVersion, {
      upgrade: (db, _oldVersion) => {
        console.log(`DB v${_oldVersion}`);
        if (_oldVersion <= 1) {
          console.log("Creating object stores");
          db.createObjectStore("logins", { keyPath: "name" });
          db.createObjectStore("servers", { keyPath: "name" });
          db.createObjectStore("threads", { keyPath: "cid" }).createIndex(
            "by-actor",
            "actor",
            { unique: false }
          );
        }
        if (_oldVersion <= 2) {
          db.createObjectStore("oras", { keyPath: "id" });
        }
      },
    });

    return new AppDB(database);
  }

  listServers() {
    return this.db.getAll("servers");
  }

  writeServer(server: ParanetServer) {
    return this.db.put("servers", server);
  }

  deleteServer(name: string) {
    return this.db.delete("servers", name);
  }

  getLogin(name: string) {
    return this.db.get("logins", name);
  }

  writeLogin(login: ILoginRecord) {
    return this.db.put("logins", login);
  }

  deleteLogin(name: string) {
    return this.db.delete("logins", name);
  }

  getThreadState(cid: string) {
    return this.db.get("threads", cid);
  }

  writeThreadState(state: IThreadState) {
    return this.db.put("threads", state);
  }

  getORA(id: string) {
    return this.db.get("oras", id);
  }

  writeOra(state: IORARecord) {
    return this.db.put("oras", state);
  }
}

export let appDatabase: AppDB;

export async function init() {
  appDatabase = await AppDB.open();
}
