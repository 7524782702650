import { Entry } from "../entities";
import { Message } from "../entities/paranet/message/Message";

export function toEntries(messages: Message[]) {
  const entries: Entry[] = messages.map((m) => ({ ...m }));
  const byId = Object.fromEntries(Object.values(entries).map((e) => [e.id, e]));
  for (const e of entries) {
    if (e.contents.type === "PncpMessage") {
      if (e.contents.data.message.reply_to) {
        e.question = byId[e.contents.data.message.reply_to];
        if (e.question) {
          e.question.answered = true;
        }
      }
    }
  }
  return entries;
}
