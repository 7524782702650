import React, { useEffect } from "react";
import { AuthService } from "../../../services";
import settings from "../../../settings";

export const CognitoAuth = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) getToken(code);
    else redirToCognito();

    // eslint-disable-next-line
  }, []);

  const getToken = (code: string) => {
    console.log("code", code);
    const redirectUri = settings.COGNITO_REDIRECT_URI;
    AuthService.getToken(code, redirectUri)
      .then((result) => {
        window.location.href = "/";
        console.log(result);
      })
      .catch((error) => {
        console.log("auth-get-token-error", error);
        // redirToCognito();
      });
  };

  const redirToCognito = () => {
    window.location.href = settings.COGNITO_LOGIN_PAGE_URL;
  };

  return <></>;
};
