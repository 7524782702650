import classNames from "classnames/bind";
import ParanetActorList from "../../components/ParanetActorList/ParanetActorList";

import styles from "./SideBar.module.scss";

const classes = classNames.bind(styles);

import { Actor } from "../../entities/paranet/actor/Actor";
import { ParanetConnection } from "../../servers";
import ParanetListSideBar from "../../components/ParanetListSideBar/ParanetListSideBar";
import { identifierToTitle } from "../../utils/utility";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDiagramProject,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import ORAList from "../../components/ORA/ORAList/ORAList";
import { useLocation } from "react-router-dom";
import { ThreadTree } from "../../entities/paranet/thread/ThreadTree";

enum Tab {
  Actors,
  ORAs,
}

interface SideBarTypes {
  actors: Actor[];
  loadingActors?: boolean;
  threadTree: ThreadTree;
  actorsSelection: string | string[];
  selectedParanet?: ParanetConnection;
  onRequestLogout: () => void;
  onRequestAddNewServer: () => void;
  onSelectParanet: (paranetId: string) => void;
}

const SideBar = ({
  actors,
  loadingActors,
  selectedParanet,
  actorsSelection,
  threadTree,
  onSelectParanet,
  onRequestLogout,
  onRequestAddNewServer,
}: SideBarTypes) => {
  const location = useLocation();
  const [open, setOpen] = useState(!selectedParanet);
  const [activeTab, setActiveTab] = useState<Tab>(
    location.pathname.startsWith("/oras") ? Tab.ORAs : Tab.Actors
  );

  useEffect(() => {
    if (!actorsSelection) {
      return;
    }

    setOpen(false);
  }, [actorsSelection]);

  return (
    <>
      <header className={styles.mobileHeader}>
        {selectedParanet && (
          <button className={styles.mobileMenu} onClick={() => setOpen(!open)}>
            <FontAwesomeIcon icon={faBars} color="#03396c" />
          </button>
        )}
        <span>
          {actorsSelection ? identifierToTitle(actorsSelection.toString()) : ""}
        </span>
      </header>
      <div className={classes(styles.sidebar, { open })}>
        <ParanetListSideBar
          activeParanet={selectedParanet}
          onRequestLogout={onRequestLogout}
          onSelectParanet={onSelectParanet}
          onRequestAddNewServer={onRequestAddNewServer}
        />
        <div className={styles.tabsWrapper}>
          <div className={styles.tabs}>
            <button
              onClick={() => setActiveTab(Tab.Actors)}
              className={classes(styles.tab, {
                active: activeTab === Tab.Actors,
              })}
            >
              <FontAwesomeIcon icon={faUsers} width="14px" /> Actors
            </button>
            <button
              onClick={() => setActiveTab(Tab.ORAs)}
              className={classes(styles.tab, {
                active: activeTab === Tab.ORAs,
              })}
            >
              <FontAwesomeIcon icon={faDiagramProject} width="14px" /> ORAs
            </button>
          </div>
          {activeTab === Tab.Actors && (
            <ParanetActorList
              actors={actors}
              threadTree={threadTree}
              loadingActors={loadingActors}
              actorsSelection={actorsSelection}
              selectedParanet={selectedParanet}
            />
          )}
          {activeTab === Tab.ORAs && (
            <ORAList loadingORAs={false} selectedParanet={selectedParanet} />
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;
