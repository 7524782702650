import {
  Thread,
  ThreadAction,
  ThreadTree,
  ThreadTreeAction,
} from "../entities/paranet/thread";
import { IThreadState, appDatabase } from "../database/database";
import { identifierToTitle, truncateSentence } from "./utility";
import { getMemberName, getOtherActor, isChat } from "./conversation.utils";
import { Conversation } from "../entities/paranet/conversation/Conversation";
import { Message } from "../entities/paranet/message/Message";

export const getThreadAge = (now: Date, th: Thread) => {
  const minutes =
    Math.abs(now.getTime() - th.lastUpdate.getTime()) / (60 * 1000);
  if (minutes >= 60) {
    const hours = minutes / 60;
    if (hours >= 24) {
      const days = hours / 24;
      return `${Math.floor(days)}d ago`;
    }
    return `${Math.floor(hours)}h ago`;
  }
  return `${Math.floor(minutes)}m ago`;
};

export const getThreadTitle = (thread: Thread, size: number) => {
  if (isChat(thread) && thread.messages) {
    for (const m of thread.messages) {
      if (m.contents.type === "SkillRequest") {
        if (m.contents.data.request.body.data.message) {
          return truncateSentence(
            `thread: ${m.contents.data.request.body.data.message}`,
            2,
            size
          );
        }
      }
    }
  }
  return `${identifierToTitle(thread.subject || "")} ${identifierToTitle(thread.action || "")}`;
};

export const isThreadUnread = (myId: string, th: Thread) => {
  const num = otherMessagesCount(myId, th);
  if (th.threadState) {
    if (th.messages && th.threadState.viewCount < num) return true;
    try {
      const last = new Date(th.threadState.lastUpdated);
      if (last.getTime() < th.lastUpdate.getTime()) return true;
    } catch (err) {
      /* empty */
    }
    return false;
  }
  return num > 0;
};

export const otherMessagesCount = (myId: string, th: Thread) => {
  return th.messages
    ? th.messages.filter((m) => m.senderEntityId != myId).length
    : 0;
};

export const markThreadAsRead = async (myId: string, th: Thread) => {
  const state = {
    actor: getMemberName(getOtherActor(myId, th)),
    cid: th.id,
    viewCount: otherMessagesCount(myId, th),
    lastUpdated: th.lastUpdate.toISOString(),
  };
  await appDatabase.writeThreadState(state);
};

export const isThreadSelected = (
  selection: string | string[],
  actor: string,
  id: string
) => {
  if (Array.isArray(selection)) {
    return selection[0] === actor && selection[1] === id;
  }

  return selection === actor && location.pathname.endsWith(id);
};

export const isThreadVisibleInTree = (th: Thread) => {
  const hours = (Date.now() - th.lastUpdate.getTime()) / (3600 * 1000);
  return hours < 24;
};

function convCreateCmpDesc(a: Conversation, b: Conversation) {
  if (a.createdAt > b.createdAt) return -1;
  if (a.createdAt < b.createdAt) return 1;
  return 0;
}

export function threadsReducer(threads: Thread[], action: ThreadAction) {
  let copy: Thread[];
  let old: number;
  switch (action.action) {
    case "reset":
      copy = action.threadList.slice(0);
      copy.sort(convCreateCmpDesc);
      return copy;
    case "append":
      copy = threads.slice(0);
      old = copy.findIndex((c) => c.id === action.thread.id);
      if (old >= 0) {
        copy[old] = action.thread;
      } else {
        copy.push(action.thread);
      }
      copy.sort(convCreateCmpDesc);
      return copy;
  }
}

export function threadTreeReducer(
  currentTree: ThreadTree,
  action: ThreadTreeAction
) {
  const tree = action.action === "reset" ? {} : { ...currentTree };
  for (const th of action.threadList) {
    const actor = getMemberName(getOtherActor(action.actorId, th)) || "_";
    if (tree[actor]) {
      const old = tree[actor].findIndex((c) => c.id === th.id);
      if (old >= 0) tree[actor][old] = th;
      else tree[actor].push(th);
    } else tree[actor] = [th];
  }
  for (const actor in tree) tree[actor].sort(convCreateCmpDesc);

  return tree;
}

export async function initThreadList(convList: Conversation[]) {
  const threads = [];
  for (const conv of convList) {
    const state = await appDatabase.getThreadState(conv.id);
    threads.push(toThread(conv, state));
  }
  return threads;
}

function msgCreateCmpAsc(a: Message, b: Message) {
  if (a.createdAt > b.createdAt) return 1;
  if (a.createdAt < b.createdAt) return -1;
  return 0;
}

export function toThread(
  conv: Conversation,
  threadState?: IThreadState
): Thread {
  const dateTime = new Date(conv.createdAt);
  const lastUpdate =
    conv.messages && conv.messages.length > 0
      ? new Date(conv.messages[conv.messages.length - 1].createdAt)
      : dateTime;
  const th = {
    dateTime,
    lastUpdate,
    threadState,
    ...conv,
  };
  if (th.messages) th.messages.sort(msgCreateCmpAsc);
  return th;
}
