import { Handle, Position } from "reactflow";

import styles from "./EntryNode.module.scss";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EntryNode = () => {
  return (
    <div className={styles.node}>
      <div className={styles.content}>
        <FontAwesomeIcon icon={faBolt} />
        <div className={styles.handleWrapper}>
          <Handle type="source" position={Position.Right} />
        </div>
      </div>
      <div className={styles.label}>Entry Point</div>
    </div>
  );
};

export default EntryNode;
