import ActionCard from "../../components/ActionCard/ActionCard";
import { useParanetContext } from "../../hooks/useParanetContext";
import { Skillset } from "../../entities/paranet/skillset/Skillset";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getMemberName } from "../../utils/conversation.utils";
import { identifierToTitle } from "../../utils/utility";

import styles from "./actorDetail.module.scss";

const ActorDetail = () => {
  const navigate = useNavigate();
  const { actor, threads, selectedParanet } = useParanetContext();
  console.log(useParanetContext());

  const [skills, setSkills] = useState<string[]>([]);
  const [skillsets, setSkillsets] = useState<Record<string, Skillset>>({});

  useEffect(() => {
    fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actor, selectedParanet]);

  const fetchSkills = async () => {
    if (!actor || !selectedParanet) {
      return;
    }

    const skillsetPromises: Promise<Skillset>[] = [];
    actor.skillSets.forEach((ss) =>
      skillsetPromises.push(selectedParanet.skillsetDB.getSkillset(ss.entityId))
    );
    const results = await Promise.allSettled(skillsetPromises);
    for (let i = 0; i < actor.skillSets.length; i++) {
      const promiseResult = results[i];
      const currSkillSet = actor.skillSets[i];
      if (promiseResult.status === "rejected") {
        continue;
      }

      skillsets[currSkillSet.entityId] = promiseResult.value;
    }

    setSkillsets({ ...skillsets });
    setSkills(actor.skillSets.map((ss) => ss.entityId));
  };

  if (!actor || !selectedParanet) {
    return;
  }

  const doesActorHasSkills = () => {
    return (
      skills.length <= 0 ||
      (skills.length === 1 && skillsets[skills[0]].skills.length <= 0)
    );
  };

  const ActorName = () => {
    const name = getMemberName(actor.entityId);
    const displayName =
      actor.metadata.name === actor.metadata.id
        ? identifierToTitle(name)
        : actor.metadata.name;
    return `${displayName}'${displayName.endsWith("s") ? "" : "s"} Actions`;
  };

  return (
    <>
      <h1 className={styles.title}>
        <ActorName />
      </h1>
      <div className={styles.gridView}>
        {doesActorHasSkills() && (
          <div style={{ color: "#000" }}>No available actions</div>
        )}
        {skills.map((id) => {
          const ss = skillsets[id];
          return ss.skills.map((sk) =>
            sk.actions.map((action, idx) => (
              <ActionCard
                key={idx}
                action={action}
                subjectId={sk.subject}
                actorsSelection={actor.entityId}
                selectedParanet={selectedParanet}
                relatedThreads={
                  threads?.filter((t) => t.action === action.action) || []
                }
                onOpenThread={(thread) =>
                  navigate(`${thread.action}/${thread.id}`)
                }
              />
            ))
          );
        })}
      </div>
    </>
  );
};

export default ActorDetail;
