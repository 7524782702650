import { ParanetConnection } from "../../servers";
import settings from "../../settings";
import { AuthClient } from "../clients/auth.client";
import { ParanetRestClient } from "../clients/paranet.rest.client";

export class ParanetRepository {
  public static async getConnections(
    token: string
  ): Promise<ParanetConnection[]> {
    const url = settings.env.AUTH_SERVICE_URI + `/paranetConnection`;
    const headers = { Authorization: `Bearer ${token}` };
    const res = await AuthClient.makeRequest(url, "GET", {}, headers);

    const connections: ParanetConnection[] = [];
    for (const data of res) {
      connections.push(new ParanetConnection(data.server, data, data.id));
    }

    return connections;
  }

  public static async addConnection(
    paranet: ParanetConnection,
    token: string
  ): Promise<ParanetConnection> {
    const url = settings.env.AUTH_SERVICE_URI + "/paranetConnection";
    const headers = { Authorization: `Bearer ${token}` };
    const res = await AuthClient.makeRequest(url, "POST", paranet, headers);

    paranet.paranetId = res.id;

    return paranet;
  }

  public static async updateConnection(
    paranet: ParanetConnection,
    token: string
  ): Promise<ParanetConnection> {
    const url = `${settings.env.AUTH_SERVICE_URI}/paranetConnection/${paranet.paranetId}`;
    const headers = { Authorization: `Bearer ${token}` };
    await AuthClient.makeRequest(url, "PUT", paranet, headers);

    return paranet;
  }

  public static async login(
    paranet: ParanetConnection,
    loginId: string,
    password: string
  ): Promise<boolean> {
    const url = paranet.server.url + "/login";
    const data = { id: loginId, password };
    const res = await ParanetRestClient.makeRequest(url, "POST", data);

    paranet.loginId = loginId;
    paranet.token = res.access_token || "";
    paranet.refresh = res.refresh_token || "";

    return true;
  }
}
