import { CognitoLoginResult } from "../../entities";
import { AuthRepository } from "../../repositories/auth/auth.repository";
import UserStorage from "../../utils/user.storage";

export class AuthService {
  public static async login(
    username: string,
    password: string
  ): Promise<{ accessToken: string; refreshToken: string; idToken: string }> {
    return await AuthRepository.login(username, password);
  }

  public static async forgotPassword(username: string): Promise<boolean> {
    return await AuthRepository.forgotPassword(username);
  }

  public static async recoveryPassword(
    username: string,
    code: string
  ): Promise<boolean> {
    return await AuthRepository.recoveryPassword(username, code);
  }

  public static async getToken(
    code: string,
    redirectUri: string
  ): Promise<CognitoLoginResult> {
    const result = await AuthRepository.getToken(code, redirectUri);
    UserStorage.set(result);
    return result;
  }
}
