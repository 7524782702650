import { ParanetConnection } from "../../servers";

import { useParanetConnectionsStore } from "../../store/useParanetConnections.store";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";

import styles from "./AddNewServerModal.module.scss";
import { ParanetService } from "../../services/paranet";

interface AddNewServerModalTypes {
  onCloseModal: () => void;
}

const initialForm = {
  url: "",
  name: "",
  debugMode: false,
};

const AddNewServerModal = ({ onCloseModal }: AddNewServerModalTypes) => {
  const addParanet = useParanetConnectionsStore((store) => store.add);

  const [form, setForm] = useState(initialForm);

  const addNewParanetServer = async () => {
    if (!form.name || !form.url) {
      console.warn("missing required fields");
      return;
    }

    const paranetData = new ParanetConnection({
      name: form.name.toLowerCase().replace(" ", "-"),
      wsUrl: form.url.replace(/^http/, "ws"),
      url: form.url,
      debug: form.debugMode,
    });

    const newParanet = await ParanetService.addConnection(paranetData);

    addParanet(newParanet);
    closeModal();
  };

  const closeModal = () => {
    setForm(initialForm);
    onCloseModal();
  };

  return (
    <Modal size="lg" show onHide={closeModal}>
      <Modal.Header closeButton style={{ background: "#03396c" }}>
        <Modal.Title>Connect to Paranet Server</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#03396c" }}>
        <div className={styles.form}>
          <div className={styles.fieldGroup}>
            <label htmlFor="name">Name</label>
            <input
              id="name"
              value={form.name}
              onChange={(e) =>
                setForm({ ...form, name: e.currentTarget.value })
              }
            />
            <small>{form.name?.toLowerCase().replace(/\s+/g, "-")}</small>
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="url">URL</label>
            <input
              id="url"
              value={form.url}
              onChange={(e) => setForm({ ...form, url: e.currentTarget.value })}
            />
          </div>
          <div className={styles.fieldGroup}>
            <label htmlFor="debug">Debug Mode?</label>
            <input
              id="debug"
              type="checkbox"
              checked={form.debugMode}
              onChange={(e) =>
                setForm({ ...form, debugMode: e.currentTarget.checked })
              }
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ background: "#03396c" }}>
        <Button variant="primary" onClick={() => addNewParanetServer()}>
          Connect
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewServerModal;
