import { Conversation, Message } from "../entities";
export const isChat = (conv: Conversation) => {
  return conv.subject === "messaging" && conv.action === "new_thread";
};

export const isPncpResponse = (m: Message) => {
  return (
    m.contents.type === "PncpMessage" &&
    m.contents.data.message.message_type === "Response"
  );
};

export const getMemberName = (id?: string) => {
  return id ? id.split("@")[0] : "Anonymous";
};

export const isConversationMine = (
  myId: string,
  conversation: Conversation
) => {
  return (
    conversation.initiator.actorEntityId === myId ||
    conversation.recipient?.actorEntityId === myId
  );
};

export const getOtherActor = (myId: string, conversation: Conversation) => {
  return conversation.initiator.actorEntityId === myId
    ? conversation.recipient?.actorEntityId
    : conversation.initiator.actorEntityId;
};
