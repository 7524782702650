export function truncateSentence(s: string, min: number, len: number): string {
  const words = s.split(" ");
  let short = "";
  for (let i = 0; i < words.length; i++) {
    if (i > 0) short += " ";
    if (i >= min && short.length + 1 + words[i].length > len)
      return `${short}...`;

    short += words[i];
  }
  return short;
}

const camelCaseToDisplayRe = /([a-zA-Z])(?=[A-Z])/g;
export function splitCamelCaseToDisplay(cc: string): string[] {
  return cc.replace(camelCaseToDisplayRe, "$1 ").split(" ");
}

function capitalize(s: string): string {
  return s[0].toUpperCase() + s.slice(1);
}

export function identifierToTitle(s: string): string {
  const words = s.split("_").flatMap(splitCamelCaseToDisplay).map(capitalize);
  return words.join(" ");
}

export function plainStringify(obj: object | []) {
  if (typeof obj !== "object" || Array.isArray(obj)) return JSON.stringify(obj);

  const props: string = Object.entries(obj)
    .map(([key, value]) => `${key}: ${plainStringify(value)}`)
    .join(", ");
  return props;
}
