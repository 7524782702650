import { identifierToTitle } from "../../utils/utility";
import { SkillAction } from "../../entities/paranet/skillset/Skillset";

import styles from "./ActionCard.module.scss";
import { Thread } from "../../entities/paranet/thread/Thread";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getMessageSummary } from "../../utils/message.util";
import { isThreadUnread } from "../../utils/thread.utils";
import classNames from "classnames/bind";
import { SyntheticEvent, useState } from "react";
import validator from "@rjsf/validator-ajv8";
import { AdaptiveCard } from "../../adaptive-card";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { appDatabase } from "../../database/database";
import { isEmptySchema, requestSchema } from "../../utils/schema.utils";

import RjsfForm, { IChangeEvent } from "@rjsf/core";
import Loader from "../Loader/Loader";
import { ParanetConnection, getActorId } from "../../servers";
import { createModalCard, getCardData } from "../../utils/adaptiveCards.utils";
import { getMemberName } from "../../utils/conversation.utils";
import { uiSchema } from "../../entities";
import { MessageSchema } from "../../entities/schema/MessageSchema";

const classes = classNames.bind(styles);

interface ActionCardTypes {
  subjectId: string;
  action: SkillAction;
  relatedThreads: Thread[];
  actorsSelection: string;
  selectedParanet: ParanetConnection;
  onOpenThread: (thread: Thread) => void;
}

interface ActiveRequest {
  actorId: string;
  subject: string;
  action: SkillAction;
  schema: MessageSchema;
}

const ActionCard = ({
  action,
  subjectId,
  relatedThreads,
  selectedParanet,
  actorsSelection,
  onOpenThread,
}: ActionCardTypes) => {
  const modalCardInst = createModalCard();

  const [isLoading, setIsLoading] = useState(false);
  const [modalFormData, setModalFormData] = useState<Record<
    string,
    string | object
  > | null>(null);
  const [activeRequest, setActiveRequest] = useState<ActiveRequest | null>(
    null
  );

  const getThreadSummary = (thread: Thread) => {
    const summary =
      thread.messages && thread.messages.length > 0
        ? thread.messages[thread.messages.length - 1]
        : undefined;
    const titleClass = isThreadUnread(actorsSelection, thread) ? "unread" : "";

    return (
      summary && (
        <span className={classes(titleClass, styles.threadSummary)}>
          {getMessageSummary(thread, summary, selectedParanet.loginId!)}
        </span>
      )
    );
  };

  const threadClickHandler = (e: SyntheticEvent, thread: Thread) => {
    e.stopPropagation();
    onOpenThread(thread);
  };

  const newRequest = (actor: string, subject: string) => {
    const actorId = getActorId(
      selectedParanet.server.name,
      getMemberName(actor)
    );
    if (!actorId) {
      return;
    }

    requestSchema(action).then((schema) => {
      setModalFormData(null);
      setActiveRequest({
        subject,
        action,
        schema,
        actorId,
      });
    });
  };

  const onChangeFormData = (data: IChangeEvent, _id?: string) => {
    const { formData } = data;
    setModalFormData(formData);
  };

  const onSubmitRequest = () => {
    if (!activeRequest) {
      return;
    }

    const actorId = selectedParanet.loginId || "guest@1.0.0";
    const data =
      activeRequest.schema.type === "AdaptiveCard"
        ? getCardData(modalCardInst)
        : modalFormData;

    selectedParanet
      .skillRequest({
        author: {
          type: "actor",
          actorId,
        },
        body: {
          type: "skill",
          subject: activeRequest.subject,
          action: activeRequest.action.action,
          data,
        },
        targetActorId: activeRequest.actorId,
      })
      .then(() => {
        setActiveRequest(null);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <>
      <div
        className={styles.container}
        onClick={() => newRequest(actorsSelection, subjectId)}
      >
        {relatedThreads.length > 0 && (
          <div className={styles.threadsInspector}>
            <div className={styles.threadsInspectorHeader}>
              <div>
                <FontAwesomeIcon icon={faMessage} />
                <span className={styles.threadsHeadline}>Last threads</span>
              </div>
              <div>{relatedThreads.length}</div>
            </div>
            <div className={styles.threadsList}>
              {relatedThreads.splice(0, 4).map((thread, idx) => (
                <div
                  key={idx}
                  className={styles.threadContainer}
                  onClick={(ev) => threadClickHandler(ev, thread)}
                >
                  {getThreadSummary(thread)}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={styles.subject}>{identifierToTitle(subjectId)}</div>
        <small>Action</small>
        <div className={styles.action}>{identifierToTitle(action.action)}</div>
      </div>
      {activeRequest && (
        <Modal
          size="lg"
          show={!!activeRequest}
          onHide={() => setActiveRequest(null)}
        >
          <Modal.Header closeButton style={{ background: "#03396c" }}>
            <Modal.Title>
              Send {activeRequest.subject}: {activeRequest.action.action}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ background: "#03396c" }}>
            {!isEmptySchema(activeRequest.schema) &&
              activeRequest.schema.type === "object" && (
                <RjsfForm
                  schema={activeRequest.schema}
                  uiSchema={uiSchema}
                  validator={validator}
                  formData={modalFormData}
                  onChange={onChangeFormData}
                />
              )}
            {activeRequest.schema.type === "AdaptiveCard" && (
              <AdaptiveCard
                card={modalCardInst}
                payload={activeRequest.schema}
              />
            )}
            {activeRequest.schema.type === "documentRef" && (
              <>
                <input
                  type="file"
                  onChange={async (event) => {
                    console.log(event.target.files);
                    if (!event.target.files) return;

                    const url = selectedParanet.server.url + "/document/upload";

                    const file = event.target.files[0];
                    const formData = new FormData();
                    formData.append("file", file);

                    const serverName = selectedParanet.server.name;
                    const localLogins = await appDatabase.getLogin(serverName);
                    console.log(localLogins);
                    const config = {
                      headers: {
                        authorization: `Bearer ${localLogins?.token}`,
                        "X-ACTOR-ID": "guest",
                      },
                    };

                    setIsLoading(true);
                    axios
                      .post(url, formData, config)
                      .then((response) => {
                        if (response.status === 200) {
                          console.log("image-id:", response.data.id);
                          console.log(
                            "image-content-type:",
                            response.data.contentType
                          );
                          const data = {
                            image: {
                              id: response.data.id,
                              contentType: response.data.contentType,
                            },
                          };
                          setModalFormData(data);
                        }
                      })
                      .finally(() => setIsLoading(false));
                  }}
                />
                {/* <input type="text" />
                <input type="text" name="contentType" value="image/jpeg" /> */}
              </>
            )}
          </Modal.Body>
          <Modal.Footer style={{ background: "#03396c" }}>
            {isLoading && <Loader />}
            <Button variant="primary" onClick={() => onSubmitRequest()}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ActionCard;
