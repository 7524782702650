import { DragEvent, useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Controls,
  Background,
  BackgroundVariant,
  OnConnect,
  DefaultEdgeOptions,
  FitViewOptions,
  MarkerType,
  ReactFlowInstance,
  Edge,
  Node,
  useReactFlow,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  NodeChange,
  EdgeChange,
} from "reactflow";
import { ActorSelectionStrategy } from "../../../entities/paranet/ora/ActorSelectionStrategy";
import { identifierToTitle } from "../../../utils/utility";
import SkillNode from "../SkillNode/SkillNode";
import { useParams } from "react-router-dom";
import { appDatabase } from "../../../database/database";
import AddNewNodeModal from "../AddNewNodeModal/AddNewNodeModal";
import {
  SkillNodeData,
  SkillNodeType,
} from "../../../entities/paranet/ora/SkillNodeType";
import ParanetGroupNode from "../ParanetGroupNode/ParanetGroupNode";
import EntryNode from "../EntryNode/EntryNode";
import ExitNode from "../ExitNode/ExitNode";

const fitViewOptions: FitViewOptions = {
  padding: 0.2,
};

const defaultEdgeOptions: DefaultEdgeOptions = {
  deletable: true,
  type: "smoothstep",
  markerEnd: MarkerType.Arrow,
  markerStart: MarkerType.ArrowClosed,
  style: {
    zIndex: 1001,
  },
};

let id = 0;
const getId = () => `dndnode_${id++}`;
const nodeTypes = {
  exitNode: ExitNode,
  entryNode: EntryNode,
  skillNode: SkillNode,
  groupNode: ParanetGroupNode,
};

const initialNodes: Node[] = [
  {
    data: null,
    id: "start",
    type: "entryNode",
    position: { x: 10, y: 10 },
  },
  {
    data: null,
    id: "end",
    type: "exitNode",
    position: { x: 200, y: 10 },
  },
];
const initialEdges: Edge[] = [];

const ORADiagram = () => {
  const { oraId } = useParams();
  const { setViewport } = useReactFlow();

  const [reactFlowInstance, setReactFlowInstance] = useState<
    ReactFlowInstance | undefined
  >();
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>(initialEdges);
  const [nodeTempData, setNodeTempData] = useState<SkillNodeType>();
  const [selectedSkillForNewNode, setSelectedSkillForNewNode] =
    useState<string>();

  useEffect(() => {
    const restoreFlow = async () => {
      if (!oraId) {
        return;
      }

      const ora = await appDatabase.getORA(oraId);
      if (!ora) {
        return;
      }

      const flow = JSON.parse(ora.jsonData);
      if (!flow) {
        return;
      }

      const { x = 0, y = 0, zoom = 1 } = flow.viewport;
      onNodesChange(flow.nodes || [], false);
      onEdgesChange(flow.edges || [], false);
      setViewport({ x, y, zoom });
      console.log("restore");
    };

    //void restoreFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveORA = useCallback(() => {
    if (reactFlowInstance && oraId) {
      const flow = reactFlowInstance.toObject();
      appDatabase.writeOra({
        id: oraId,
        name: `${identifierToTitle(oraId?.replace("-", "_") || "New ORA")}`,
        jsonData: JSON.stringify(flow),
      });
      console.log("save");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactFlowInstance]);

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      if (!reactFlowInstance) {
        return;
      }

      event.preventDefault();

      const skillData = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof skillData === "undefined" || !skillData) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX - 80,
        y: event.clientY - 40,
      });
      const [_skillId, skillTitle, actorName, paranetUrl] =
        skillData.split("|");
      const newNode: SkillNodeType = {
        id: getId(),
        position,
        type: "skillNode",
        data: {
          externalParanetUrl: paranetUrl,
          actorName: identifierToTitle(actorName),
          strategy: ActorSelectionStrategy.BestFit,
          skillTitle: `${identifierToTitle(skillTitle)}`,
        },
      };

      setNodeTempData(newNode);
      setSelectedSkillForNewNode(skillData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance]
  );

  const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodesChange = useCallback(
    (changes: NodeChange[], save = false) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
      if (save) {
        saveORA();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[], save = false) => {
      setEdges((eds) => applyEdgeChanges(changes, eds));
      if (save) {
        saveORA();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEdges]
  );

  const onNodeConnect: OnConnect = useCallback(
    (connection, save = false) => {
      setEdges((eds) => addEdge(connection, eds));
      if (save) {
        saveORA();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEdges]
  );

  const addNewNode = (_data: Record<string, string | object> | undefined) => {
    if (!nodeTempData) {
      return;
    }

    const pnUrl = nodeTempData.data?.externalParanetUrl;
    if (pnUrl) {
      const hasParanetGroup = nodes.find(
        (n) => (n.data as SkillNodeData)?.externalParanetUrl === pnUrl
      );
      if (hasParanetGroup) {
        setNodes((nds) =>
          nds.concat({ ...nodeTempData, parentNode: pnUrl, extent: "parent" })
        );
      } else {
        const paranetGroup: Node = {
          id: pnUrl,
          type: "groupNode",
          data: { name: pnUrl },
          position: { x: 0, y: 0 },
          style: {
            minWidth: 300,
            minHeight: 300,
            borderRadius: "4px",
            border: "2px solid #005b96",
            backgroundColor: "#ff9a9a4d",
          },
        };

        setNodes((nds) => {
          const newNodes: Node[] = [
            paranetGroup,
            { ...nodeTempData, parentNode: pnUrl, extent: "parent" },
          ];
          return newNodes.concat(nds);
        });
      }
    } else {
      setNodes((nds) => nds.concat(nodeTempData));
    }

    setNodeTempData(undefined);
    setSelectedSkillForNewNode(undefined);
  };

  return (
    <>
      <ReactFlow
        fitView
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitViewOptions={fitViewOptions}
        defaultEdgeOptions={defaultEdgeOptions}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onConnect={onNodeConnect}
        onInit={setReactFlowInstance}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
      >
        <Controls />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
      {selectedSkillForNewNode && (
        <AddNewNodeModal
          skill={selectedSkillForNewNode}
          onAddNode={addNewNode}
          onClose={() => setSelectedSkillForNewNode(undefined)}
        />
      )}
    </>
  );
};

export default ORADiagram;
