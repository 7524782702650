import ReconnectingWebSocket from "reconnecting-websocket";
import { ParanetClient, TokenResponse } from "paranet-client-ts";
import { ILoginRecord, appDatabase } from "./database/database";
import { Conversation } from "./entities/paranet/conversation/Conversation";
import { Message } from "./entities/paranet/message/Message";
import { useParanetConnectionsStore } from "./store/useParanetConnections.store";
import { ParanetServer } from "./entities/paranet/ParanetServer";
import { listActors } from "./services/actor.service";
import { SkillRequestBody } from "./entities/paranet/SkillRequestBody";
import { getMemberName } from "./utils/conversation.utils";
import { SkillsetDB } from "./services/skillsets.service";
import {
  Actor,
  ActorCaller,
  ActorDB,
  MessageData,
  PncpMessage,
  SkillQnA,
} from "./entities";
export class ParanetConnection {
  paranetId: number;
  server: ParanetServer;
  connected: ConnectionState;
  loginId?: string;
  token?: string;
  refresh?: string;
  refreshTimestamp: number; // time of last refresh
  skillsetDB: SkillsetDB;
  client?: ParanetClient;
  webSocket?: ReconnectingWebSocket;

  wsHandler?: (evt: MessageEvent) => void;

  logoutHandler?: () => void;

  constructor(server: ParanetServer, credentials?: ILoginRecord, id?: number) {
    // this.paranetId = crypto.randomUUID();
    this.paranetId = id || 0;
    this.server = server;
    this.connected = "disconnected";
    this.refreshTimestamp = 0;
    this.skillsetDB = new SkillsetDB(this.server.url);
    if (this.server.name === "local") {
      this.loginId = "guest@1.0.0";
    } else if (credentials) {
      this.token = credentials.token;
      this.loginId = credentials.loginId;
    }
  }

  // Logout handler is called if tokens expire and cannot be refreshed
  setLogoutHandler(handler: () => void) {
    this.logoutHandler = handler;
  }

  // Make a graphql request.
  // Handles authentication and token refresh

  async graphqlRequest(
    name: string,
    query: string,
    variables: Record<string, string | number | null>
  ) {
    const headers = this.makeHeaders();
    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    };
    const result = await this.post(
      `${this.server.url}/graphql`,
      requestOptions
    );
    if ("errorCode" in result) {
      // HTTP error status
      throw new Error(`HTTP error ${result.errorCode}: ${result.content}`);
    } else if (result.errors) {
      // GraphQL errors
      throw new Error(`GraphQL ${name} error: ${result.errors[0].message}`);
    }
    return result.data[name];
  }

  async skillRequest(request: SkillMessageRequest) {
    const headers = this.makeHeaders();
    const requestOptions = {
      method: "POST",
      headers,
      body: JSON.stringify(request),
    };
    return this.post(`${this.server.url}/skill`, requestOptions);
  }

  async sendQuestion(conv: Conversation, qu: SkillQnA, data: MessageData) {
    const message = {
      conversation: conv.initiator.memberId,
      message: {
        message_type: "Question",
        id: qu.id,
        data,
      },
    };
    await this.sendPncpMessage(message);
  }

  async sendStatus(conv: Conversation, data: MessageData) {
    const memberId =
      conv.initiator.actorEntityId === this.loginId
        ? conv.initiator.memberId
        : conv.recipient?.memberId;
    if (memberId) {
      const message = {
        conversation: memberId,
        message: {
          message_type: "Status",
          data,
        },
      };
      await this.sendPncpMessage(message);
    } else {
      throw Error("Conversation is missing member id");
    }
  }

  async sendReply(conv: Conversation, m: Message, data: MessageData) {
    if (m.contents.type === "PncpMessage") {
      const message = {
        conversation: conv.initiator.memberId,
        message: {
          message_type: "Answer",
          reply_to: m.id,
          data,
        },
      };
      await this.sendPncpMessage(message);
    } else if (m.contents.type === "SkillRequest") {
      const headers = this.makeHeaders();
      const message = {
        conversation: conv.recipient?.memberId,
        message: {
          message_type: "Response",
          data,
        },
      };
      console.dir(message);
      const packet = { type: "Message", ...message };
      const responseRequest = {
        method: "POST",
        headers,
        body: JSON.stringify(packet),
      };
      return await this.post(
        `${this.server.url}/message/${this.loginId}`,
        responseRequest
      );
    }
  }

  private async sendPncpMessage(message: PncpMessage) {
    const headers = this.makeHeaders();
    const packet = { type: "Message", ...message };
    const pncpRequest = {
      method: "POST",
      headers,
      body: JSON.stringify(packet),
    };
    return await this.post(`${this.server.url}/data`, pncpRequest);
  }

  private makeHeaders() {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
      "X-ACTOR-ID": this.loginId!,
    };
    if (this.server.name !== "local")
      headers.Authorization = `Bearer ${this.token}`;
    return headers;
  }

  // HTTP post with automatic token refresh
  private async post(url: string, options: RequestInit) {
    let resp = await fetch(url, options).catch(console.error);
    if (!resp) {
      return { errorCode: 0, content: "Server unreachable" };
    }

    // If unauthorized and didn't already refresh, try refreshing token
    // REST APIs return 401 status
    if (resp.status == 401) {
      if (await this.refreshToken()) {
        options.headers = this.makeHeaders();
        resp = await fetch(url, options);
      }
    }

    if (resp.status == 200) {
      let result = await resp.json();
      // GraphQL APIs return Forbidden in response body
      if (this.isUnauthorizedGraphQL(result)) {
        if (await this.refreshToken()) {
          options.headers = this.makeHeaders();
          const resp2 = await fetch(url, options);
          if (resp2.status == 200) result = await resp2.json();
        }
      }

      return result;
    }

    const body = await resp.text();
    return { errorCode: resp.status, content: body };
  }

  private isUnauthorizedGraphQL(result: { errors?: { message: string }[] }) {
    return (
      "errors" in result &&
      Array.isArray(result.errors) &&
      result.errors.some(
        (err) =>
          "message" in err && err.message.toLowerCase().includes("forbidden")
      )
    );
  }

  private async refreshToken() {
    if (!this.client || Date.now() - this.refreshTimestamp <= 60000) {
      return false;
    }

    try {
      console.log(`Refreshing ${this.server.name} credentials ...`);
      const { access_token, refresh_token } = await this.client.refreshAuth();
      if (access_token && refresh_token) {
        this.token = access_token;
        this.refresh = refresh_token;
        // async, but don't need to wait
        this.storeLogin();
        console.log(`Refreshed ${this.server.name} credentials`);
        return true;
      }
    } catch (err) {
      if (err instanceof Error) {
        if (err.message.toLowerCase().includes("expired")) {
          console.log(
            "Refresh credentials expired, removing store credentials.  New login required."
          );
          if (this.webSocket) this.disconnect();
          await this.clearLogin();
          if (this.logoutHandler) this.logoutHandler();
        } else {
          console.log(
            `Error refreshing ${this.server.name} credentials: ${err.message}`
          );
        }
      }
    }

    return false;
  }

  // paranet-client version of this is broken
  async refreshAuth() {
    if (!this.client) {
      return { error: "missing client" };
    }

    const result = await this.client.post<TokenResponse | { error: string }>(
      this.server.url,
      "token/refresh",
      {
        refresh_token: this.refreshToken,
      }
    );
    if ("access_token" in result) {
      this.client.setTokens(result.access_token, result.refresh_token);
    }
    return result;
  }

  private async clearLogin() {
    this.token = undefined;
    this.loginId = undefined;
    this.refresh = undefined;
    await appDatabase.deleteLogin(this.server.name);
  }

  private async storeLogin() {
    if (this.loginId && this.token && this.refresh) {
      await appDatabase.writeLogin({
        name: this.server.name,
        username: getMemberName(this.loginId),
        token: this.token,
        refresh: this.refresh,
      });
    }
  }
}

export type ConnectionState = "connected" | "disconnected" | "pending";

export type LoginResult =
  | {
      token: string;
      refresh: string;
    }
  | {
      error: string;
    };

export interface SkillMessageRequest {
  author: RequestCaller;
  body: SkillRequestBody;
  targetActorId?: string;
}

export interface ParaviewCaller {
  type: "paraview";
  actorId: string;
}

export type RequestCaller = ActorCaller | ParaviewCaller;

export interface SkillRequestResponse {
  id: string;
  messageId: string;
}

const actorCache: Record<string, ActorDB> = {};

export const loadActorsFromParanet = async (
  paranetName: string
): Promise<Actor[]> => {
  if (!actorCache[paranetName]) {
    const paranets = useParanetConnectionsStore.getState().connections;
    const paranet = paranets.get(paranetName);
    if (!paranet) {
      throw new Error(`Invalid paranet ${paranetName}`);
    }
    actorCache[paranetName] = await listActors(paranet);
  }
  return Object.values(actorCache[paranetName]).map(
    (set) => set.versions[set.current]
  );
};

export function getActorId(net: string, name: string) {
  console.log(Object.keys(actorCache));
  if (actorCache[net] && actorCache[net][name]) {
    return `${name}@${actorCache[net][name].current}`;
  }

  console.warn(`Invalid actor ${name}`);
  return null;
}
