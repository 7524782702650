import { useParanetContext } from "../../hooks/useParanetContext";
import { DragEvent } from "react";
import { identifierToTitle } from "../../utils/utility";
import { ReactFlowProvider } from "reactflow";
import { useParams } from "react-router-dom";

import NodesPanel from "../../components/ORA/NodesPanel/NodesPanel";
import ORADiagram from "../../components/ORA/ORADiagram/ORADiagram";
import { useParanetConnectionsStore } from "../../store/useParanetConnections.store";

import "reactflow/dist/style.css";
import styles from "./oraDetail.module.scss";

const ORADetail = () => {
  const { oraId } = useParams();
  const { selectedParanet } = useParanetContext();

  const ORATitle = () => {
    return `${identifierToTitle(oraId?.replace("-", "_") || "New ORA")}`;
  };

  const onDragStart = (event: DragEvent<HTMLDivElement>, actorId: string) => {
    if (!event.dataTransfer) {
      return;
    }

    event.dataTransfer.setData("application/reactflow", actorId);
    event.dataTransfer.effectAllowed = "move";
  };

  if (!selectedParanet) {
    return <div>No paranet selected</div>;
  }

  return (
    <>
      <h1 className={styles.title}>
        <ORATitle />
      </h1>
      <div className={styles.container}>
        <ReactFlowProvider>
          <div className={styles.reactFlowWrapper}>
            <ORADiagram />
          </div>
          <NodesPanel onDragStart={onDragStart} />
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default ORADetail;
