import {
  faArrowAltCircleRight,
  faComputer,
  faGlobe,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { identifierToTitle } from "../../../utils/utility";
import classNames from "classnames/bind";

import { DragEvent, useEffect, useState } from "react";
import * as serverUtils from "../../../servers";
import { useParanetContext } from "../../../hooks/useParanetContext";

import styles from "./NodesPanel.module.scss";
import { Actor, ParanetServer, Skillset } from "../../../entities";
import { ParanetConnection } from "../../../servers";
import LoginModal from "../../LoginModal/LoginModal";
import { useParanetConnectionsStore } from "../../../store/useParanetConnections.store";
import { appDatabase } from "../../../database/database";

const classes = classNames.bind(styles);

enum Tab {
  Local,
  Remote,
}

interface NodesPanelProps {
  onDragStart: (event: DragEvent<HTMLDivElement>, skillName: string) => void;
}

const NodesPanel = ({ onDragStart }: NodesPanelProps) => {
  const { actors, selectedParanet } = useParanetContext();

  const [actions, setActions] = useState<
    { id: string; subject: string; action: string }[]
  >([]);
  const [externalActions, setExternalActions] = useState<
    { id: string; subject: string; action: string; paranet: ParanetServer }[]
  >([]);
  const [skillSearchText, setSkillSearchText] = useState("");
  const [paranetLookupUrl, setParanetLookupUrl] = useState(
    "https://grokit.paranet.ai/api/paranet-service"
  );
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Local);
  const [paranetLookup, setParanetLookup] = useState<ParanetConnection>();

  useEffect(() => {
    const fetch = async () => {
      const skills = await fetchSkills(actors!);
      setActions(skills || []);
    };

    if (actors) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actors]);

  const fetchSkills = async (actorList: Actor[]) => {
    if (!actorList || !selectedParanet) {
      console.error("No actors found");
      return;
    }

    const skillsetPromises: Promise<Skillset>[] = [];
    const skillsets: Record<string, Skillset> = {};
    const flatSkillSets = actorList.flatMap((actor) => actor.skillSets);
    flatSkillSets.forEach((ss) =>
      skillsetPromises.push(selectedParanet.skillsetDB.getSkillset(ss.entityId))
    );
    const results = await Promise.allSettled(skillsetPromises);
    for (let i = 0; i < skillsetPromises.length; i++) {
      const promiseResult = results[i];
      const currSkillSet = flatSkillSets[i];
      if (promiseResult.status === "rejected") {
        continue;
      }

      skillsets[currSkillSet.entityId] = promiseResult.value;
    }

    return flatSkillSets
      .flatMap((ss) =>
        skillsets[ss.entityId]?.skills.flatMap((sk) =>
          sk.actions.map((action) => ({
            id: ss.entityId,
            subject: sk.subject,
            action: action.action,
          }))
        )
      )
      .filter((ss) => ss)
      .sort((a1, a2) => a1.action.localeCompare(a2.action));
  };

  const submitSearch = () => {
    const newParanet = new ParanetConnection({
      name: paranetLookupUrl,
      wsUrl: paranetLookupUrl.replace(/^http/, "ws"),
      url: paranetLookupUrl,
      debug: false,
    });

    //addParanet(newParanet);
    appDatabase.writeServer(newParanet.server);
    setParanetLookup(newParanet);
  };

  const loadSkillsFromParanet = () => {
    if (!paranetLookup) {
      return;
    }

    paranetLookup.connect((name, status) => {
      console.log(name, "paranet connected", status);
      serverUtils
        .loadActorsFromParanet(paranetLookup.server.name)
        .then(async (actorList) => {
          const skills = await fetchSkills(actorList);
          setExternalActions(
            (_prev) =>
              skills?.map((sk) => ({ ...sk, paranet: paranetLookup.server })) ||
              []
          );
        });
      setParanetLookup(undefined);
    });
  };

  return (
    <div className={styles.nodePanel}>
      <div className={styles.title}>Skills</div>
      <div className={styles.tabs}>
        <button
          onClick={() => setSelectedTab(Tab.Local)}
          className={classes(styles.tab, {
            active: selectedTab === Tab.Local,
          })}
        >
          <FontAwesomeIcon icon={faComputer} size="xs" />
          <span>Local</span>
        </button>
        <button
          onClick={() => setSelectedTab(Tab.Remote)}
          className={classes(styles.tab, {
            active: selectedTab === Tab.Remote,
          })}
        >
          <FontAwesomeIcon icon={faGlobe} size="xs" />
          <span>Remote</span>
        </button>
      </div>
      {selectedTab === Tab.Local && (
        <div className={styles.tabContent}>
          <div className={styles.searchBar}>
            <FontAwesomeIcon icon={faSearch} size="xs" color="#005b96" />
            <input
              type="text"
              value={skillSearchText}
              className={styles.searchField}
              placeholder="Actor or Skill name"
              onChange={(ev) => setSkillSearchText(ev.currentTarget.value)}
            />
          </div>
          <div className={styles.list}>
            {actions.map((action, idx) => (
              <div
                draggable
                className={styles.node}
                key={`${action.id}|${action.action}|${action.subject}|${idx}`}
                onDragStart={(event) =>
                  onDragStart(
                    event,
                    `${action.id}|${action.action}|${action.subject}`
                  )
                }
              >
                <span>{identifierToTitle(action.action)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedTab === Tab.Remote && (
        <div className={styles.tabContent}>
          <div className={styles.searchBar}>
            <FontAwesomeIcon icon={faGlobe} size="xs" color="#005b96" />
            <input
              type="url"
              value={paranetLookupUrl}
              className={styles.searchField}
              placeholder="Paranet lookup URL"
              onChange={(ev) => setParanetLookupUrl(ev.currentTarget.value)}
            />
            <button
              type="button"
              onClick={submitSearch}
              className={styles.btnSubmit}
            >
              <FontAwesomeIcon
                size="xs"
                color="#005b96"
                icon={faArrowAltCircleRight}
              />
            </button>
          </div>
          <div className={styles.list}>
            {externalActions.map((action) => (
              <div
                draggable
                className={styles.node}
                key={`${action.id}|${action.action}|${action.subject}|${action.paranet.url}`}
                onDragStart={(event) =>
                  onDragStart(
                    event,
                    `${action.id}|${action.action}|${action.subject}|${action.paranet.url}`
                  )
                }
              >
                <span>{identifierToTitle(action.action)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {paranetLookup && (
        <LoginModal
          selectedParanet={paranetLookup}
          setServerState={(state) => {
            if (state === "ready") {
              loadSkillsFromParanet();
            }
          }}
        />
      )}
    </div>
  );
};

export default NodesPanel;
