import { UiSchema } from "@rjsf/utils";

export const uiSchema: UiSchema = {
  "ui:submitButtonOptions": {
    norender: true,
  },
  password: {
    "ui:widget": "password",
  },
};
