import { ActorDetails, Skillset } from "../entities";

export class SkillsetDB {
  endpoint: string;

  skillsets: { [key: string]: Skillset };

  constructor(endpoint: string) {
    this.endpoint = endpoint;
    this.skillsets = {};
  }

  async getSkillset(name: string) {
    if (!this.skillsets[name]) {
      console.log("GET Skillset", name);
      await this.fetchSkillset(name);
      console.log(this.skillsets[name]);
    }
    if (this.skillsets[name]) {
      return this.skillsets[name];
    }
    throw new Error(`Failed to fetch Skillset ${name}`);
  }

  async fetchSkillset(id: string) {
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `
        query getSkill($id: EntityId!) {
          skillSetDetails(entityId: $id) {
            entityId
            skills {
              subject
              actions {
                action
                inputRef
                inputForm
                outputRef
                inputDisplay
                outputDisplay
                requesterQuestions {
                  id
                  name
                  question
                  questionForm
                  questionDisplay
                  answer
                  answerForm
                  answerDisplay
                }
                fulfillerQuestions {
                  id
                  name
                  question
                  questionForm
                  questionDisplay
                  answer
                  answerForm
                  answerDisplay
                }
              }
            }
          }
        }
        `,
        variables: { id },
      }),
    };
    const result = await fetch(`${this.endpoint}/graphql`, requestOptions).then(
      (response) => response.json()
    );
    if (result.errors) {
      // might be an actor name
      requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: `
          query getSkill($id: EntityId!) {
            actorDetails(entityId: $id) {
              skillSets {
                entityId
                skills {
                  subject
                  actions {
                    action
                    inputRef
                    inputForm
                    outputRef
                    inputDisplay
                    outputDisplay
                    requesterQuestions {
                      id
                      name
                      question
                      questionForm
                      questionDisplay
                      answer
                      answerForm
                      answerDisplay
                    }
                    fulfillerQuestions {
                      id
                      name
                      question
                      questionForm
                      questionDisplay
                      answer
                      answerForm
                      answerDisplay
                    }
                  }
                }
              }
            }
          }
          `,
          variables: { id },
        }),
      };
      const result2 = await fetch(
        `${this.endpoint}/graphql`,
        requestOptions
      ).then((response) => response.json());
      if (result2.errors) {
        throw new Error(result.errors[0].message); // throw original
      }
      const details = result2.data.actorDetails as ActorDetails;
      for (const ss of details.skillSets) {
        if (ss.entityId == id) {
          this.skillsets[id] = ss;
          return true;
        }
      }
    } else {
      const ss = result.data.skillSetDetails as Skillset;
      this.skillsets[id] = ss;
      return true;
    }
    return false;
  }
}
