import { Conversation, Message, MessageData } from "../entities";
import { getMemberName, isChat } from "./conversation.utils";
import { identifierToTitle, plainStringify } from "./utility";

export const getMessageSummary = (
  conv: Conversation,
  m: Message,
  myId: string
) => {
  const sender =
    myId === m.senderEntityId
      ? "Me"
      : identifierToTitle(getMemberName(m.senderEntityId));
  if (m.contents.type === "SkillRequest") {
    if (isChat(conv) && m.contents.data.data) {
      if (m.contents.data.data.data.message) {
        return (
          <>
            <span className="author">{sender}</span>:{" "}
            {m.contents.data.data.data.message}
          </>
        );
      }
    }
    return (
      <>
        <span className="author">{sender}</span>:{" "}
        {m.contents.data.request.body.action} request{" "}
        {m.contents.data.data?.data &&
          getDataSummary("with", m.contents.data.data.data)}
      </>
    );
  }
  if (m.contents.type === "PncpMessage") {
    if (m.contents.data.message.message_type === "Response") {
      return (
        <>
          <span className="author">{sender}</span>: responded{" "}
          {getDataSummary("with", m.contents.data.message.data)}
        </>
      );
    }
    if (m.contents.data.message.message_type === "Question") {
      return (
        <>
          <span className="author">{sender}</span>: {m.contents.data.message.id}{" "}
          {getDataSummary("with", m.contents.data.message.data)}
        </>
      );
    }
    if (m.contents.data.message.message_type === "Answer") {
      return (
        <>
          <span className="author">{sender}</span>: answered{" "}
          {getDataSummary("with", m.contents.data.message.data)}
        </>
      );
    }
    if (isChat(conv) && m.contents.data.message.data.message) {
      return (
        <>
          <span className="author">{sender}</span>:{" "}
          {m.contents.data.message.data.message}
        </>
      );
    }
    return (
      <>
        <span className="author">{sender}</span>:{" "}
        {m.contents.data.message.message_type.toLowerCase()}{" "}
        {plainStringify(m.contents.data.message.data)}
      </>
    );
  }
  return <div>{sender}</div>;
};

export function getDataSummary(prefix: string, data: MessageData) {
  removeNuls(data);
  if (Object.keys(data).length > 0) return `${prefix} ${plainStringify(data)}`;
  return "";
}

export function removeNuls(obj: MessageData) {
  if (Array.isArray(obj)) {
    for (const i in obj) {
      if (obj[i] === null) {
        obj[i] = "";
      } else {
        removeNuls(obj[i]);
      }
    }
  } /*else if (typeof obj === "object") {
    for (const k in obj) {
      if (k in obj) obj[k] = "";
      else removeNuls(obj[k]);
    }
  }*/
}
