import { Conversation, ConversationList } from "../entities";
import { ParanetConnection } from "../servers";

export async function listActorConversations(
  pn: ParanetConnection,
  actorId: string
) {
  const limit = 200;
  // Iteration token; _not_ the authentication token.
  let token = null;
  let conversations: Conversation[] = [];
  // eslint-disable-next-line no-constant-condition
  const [id] = actorId.split("@");
  let isRunning = true;
  while (isRunning) {
    const convResult = (await pn.graphqlRequest(
      "listConversationsByActorId",
      `query list($id: Id, $token: String, $limit: Int!) {
        listConversationsByActorId(id: $id, descending: true, limit: $limit, token: $token) {
          conversations {
            id
            parentId
            createdAt
            state
            subject
            action
            initiator {
              memberId
              actorEntityId
            }
            recipient {
              memberId
              actorEntityId
            }
          }
          lastToken
        }
      }`,
      { id, limit, token }
    )) as ConversationList;
    console.log("convResult:", convResult);
    conversations = conversations.concat(convResult.conversations);
    if (!convResult.lastToken || convResult.conversations.length < limit) {
      isRunning = false;
      break;
    }
    token = convResult.lastToken;
  }
  console.log("LOAD", id, "COVERSATIONS", conversations.length);
  return conversations;
}

export async function loadConversation(pn: ParanetConnection, cid: string) {
  const convResult = (await pn.graphqlRequest(
    "conversationById",
    `query get($cid: Id!) {
    conversationById(id: $cid) {
      id
      parentId
      createdAt
      state
      subject
      action
      initiator {
        memberId
        actorEntityId
      }
      recipient {
        memberId
        actorEntityId
      }
      messages {
        id
        conversationId
        createdAt
        senderEntityId
        contents
      }
    }
  }`,
    { cid }
  )) as Conversation;
  return convResult;
}
