import React, { useState } from "react";
import { AuthService } from "../../../services";

import "./login.scss";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";

export const Login = () => {
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const login = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!form.username) {
      alert("Type the username.");
      return;
    }

    if (!form.password) {
      alert("Type the password.");
      return;
    }

    setIsLoading(true);
    AuthService.login(form.username, form.password)
      .then((result) => {
        console.log(result);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="loginPage">
      {isLoading && <Loader />}
      <div className="container">
        <h2 className="title">Login</h2>
        <form onSubmit={login} className="form">
          <div className="formControl">
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={form.username}
              onChange={(event) =>
                setForm({ ...form, username: event.target.value })
              }
            />
          </div>
          <div className="formControl">
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={form.password}
              onChange={(event) =>
                setForm({ ...form, password: event.target.value })
              }
            />
          </div>
          <div className="buttons">
            <button type="submit">Login</button>
          </div>
        </form>
        <div className="links">
          <Link to="/auth/forgot-password">Forgot my password</Link>
        </div>
      </div>
    </div>
  );
};
