import { Handle, NodeProps, Position } from "reactflow";
import { ActorSelectionStrategy } from "../../../entities/paranet/ora/ActorSelectionStrategy";

import styles from "./SkillNode.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faRightToBracket,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useState } from "react";
import StrategySelectionPopup from "./StrategySelectionPopup/StrategySelectionPopup";
import ConfigOutputTypesModal from "../ConfigOutputTypesModal/ConfigOutputTypesModal";
import { SkillNodeData } from "../../../entities/paranet/ora/SkillNodeType";

const SkillNode = ({ data }: NodeProps<SkillNodeData>) => {
  const [outputTypesModalShown, setOutputTypesModalShown] = useState(false);
  const [actorSelectionStrategy, setActorSelectionStrategy] = useState(
    data.strategy
  );
  return (
    <div className={styles.node}>
      <Handle type="target" position={Position.Left} />
      <div className={styles.content}>
        <div className={styles.topBar}>
          <div className={styles.warnings}>
            {actorSelectionStrategy ===
              ActorSelectionStrategy.SpecificActor && (
              <button className={classNames(styles.button, "nodrag")}>
                <FontAwesomeIcon icon={faWarning} size="xs" color="#e86e30" />
              </button>
            )}
          </div>
          <StrategySelectionPopup
            actorSelection={data.actorName}
            initialStrategy={actorSelectionStrategy}
            onStrategyChange={setActorSelectionStrategy}
          />
        </div>
        <div>{data.skillTitle}</div>
        <div className={styles.bottomBar}>
          <button className={classNames(styles.button, "nodrag")}>
            <FontAwesomeIcon size="xs" icon={faRightToBracket} />
          </button>
          <button
            onClick={() => setOutputTypesModalShown(true)}
            className={classNames(styles.button, "nodrag")}
          >
            <FontAwesomeIcon size="xs" icon={faRightFromBracket} />
          </button>
        </div>
        {outputTypesModalShown && (
          <ConfigOutputTypesModal
            skillTitle={data.skillTitle}
            onClose={() => setOutputTypesModalShown(false)}
          />
        )}
      </div>
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

export default SkillNode;
