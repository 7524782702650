import { Button, Modal } from "react-bootstrap";

interface ConfigOutputTypesModalTypes {
  skillTitle: string;
  onClose: () => void;
}

const ConfigOutputTypesModal = ({
  skillTitle,
  onClose,
}: ConfigOutputTypesModalTypes) => {
  return (
    <Modal size="lg" show onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Configure Output Types - {skillTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label htmlFor="debug">Screen Capture (image)</label>
          <input id="debug" type="checkbox" />
        </div>
        <div>
          <label htmlFor="lat">Lat (number)</label>
          <input id="lat" type="checkbox" />
        </div>
        <div>
          <label htmlFor="long">Long (number)</label>
          <input id="long" type="checkbox" />
        </div>
        <div>
          <label htmlFor="timestamp">Timestamp (number)</label>
          <input id="timestamp" type="checkbox" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => onClose()}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfigOutputTypesModal;
