import {
  ParaflowDependency,
  ParaflowGoalRow,
  ParaflowLogItem,
  ParaflowLogView,
  ParaflowView,
  TimelineItem,
  createTimeline,
  fetchLog,
} from "react-paraflow";

import { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";

import "./paraflowlog.css";

const proxy = "http://localhost:3023";

interface Props {
  actorId: string;
  cid: string;
  count: number;
  refresh: number;
}

function toGraphql(x: any): string {
  if (Array.isArray(x)) {
    const elements = x.map(toGraphql).join(", ");
    return `[${elements}]`;
  }
  if (typeof x === "object") {
    const fields = Object.entries(x)
      .map(([k, v]) => `${k}: ${toGraphql(v)}`)
      .join(", ");
    return `{${fields}}`;
  }
  return JSON.stringify(x);
}

async function repostGraphql(log: ParaflowLogItem) {
  if ("service" in log.data && log.data.service === "graphql") {
    const { name, remote, actuals, schema } = log.data;
    const call = name.substring(1); // remove @
    const inputs = Object.entries(actuals)
      .map(([k, v]) => `${k}: ${toGraphql(v)}`)
      .join(", ");
    const projection = schema ? " {}" : "";
    const query = `mutation { ${call}(${inputs})${projection} }`;
    const body = {
      query,
      variables: {},
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    return await fetch(`${remote}/graphql`, requestOptions).then((result) =>
      result.json()
    );
  }
}

async function fetchDepenencies(actorId: string, uid: string) {
  const ts = new Date().getTime();
  const url = `${proxy}/${actorId}/_debug/dependencies?rid=${encodeURIComponent(
    uid
  )}&ts=${ts}`;
  return await fetch(url).then((result) => result.json());
}

async function loadGoals(
  actorId: string,
  cid: string
): Promise<ParaflowGoalRow[]> {
  const ts = new Date().getTime();
  const page = 0;
  const goalPageSize = 1000;
  const offset = page * goalPageSize;

  return await fetch(
    `${proxy}/${actorId}/_debug/goalrows?offset=${offset}&limit=${goalPageSize}&ts=${ts}&cid=${cid}`
  ).then((res) => res.json());
}

const postOptions = (data: any) => ({
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(data),
});

export function Debugger({ actorId, cid, count, refresh }: Props) {
  const [restarts, setRestarts] = useState(0);
  const [goals, setGoals] = useState<ParaflowGoalRow[]>([]);
  const [dependencies, setDependencies] = useState<ParaflowDependency[]>([]);
  const [timeline, setTimeline] = useState<TimelineItem[]>([]);
  const onOpenChild = () => {}; // unused

  useEffect(() => {
    loadGoals(actorId, cid).then((rows) => {
      setGoals(rows);
      const root = rows.find((r) => !r.pid);
      if (root) {
        const goalSet = Object.fromEntries(rows.map((r) => [r.uid, r]));
        fetchLog(`${proxy}/${actorId}`, root.rid).then((logs) => {
          setTimeline(createTimeline(root.uid, logs, goalSet, true));
        });
        fetchDepenencies(actorId, root.uid).then((deps) =>
          setDependencies(deps)
        );
      }
    });
  }, [count, refresh, restarts]);

  const handleGoalTool = (tool: string, goal: ParaflowGoalRow) => {
    console.log(tool, goal.uid);
    switch (tool) {
      case "retry":
        fetch(
          `${proxy}/${actorId}/retry/${goal.name}/${goal.uid}`,
          postOptions("")
        ).then(() => {
          setRestarts(restarts + 1);
        });
        break;
      case "complete":
        fetch(
          `${proxy}/${actorId}/set_state/complete/${goal.name}/${goal.uid}`,
          postOptions("")
        ).then(() => {
          setRestarts(restarts + 1);
        });
        break;
      case "cancel":
        fetch(
          `${proxy}/${actorId}/set_state/canceled/${goal.name}/${goal.uid}`,
          postOptions("")
        ).then(() => {
          setRestarts(restarts + 1);
        });
        break;
    }
  };

  const handleLogTool = (tool: string, log: ParaflowLogItem) => {
    repostGraphql(log)
      .then((result) => {
        if (result.errors) {
          const details = result.errors
            .map((e: Record<string, any>) => e.message)
            .join(", ");
          alert(`GraphQL errors: ${details}`);
        } else {
          alert("Re-sent request");
        }
      })
      .catch((err) => alert(err.message));
  };

  return (
    <Tab.Container defaultActiveKey="workflow">
      <Nav variant="tabs">
        <Nav.Link eventKey="workflow">Workflow</Nav.Link>
        <Nav.Link eventKey="log">Log</Nav.Link>
      </Nav>
      <Tab.Content className="py-2">
        <Tab.Pane eventKey="workflow">
          <ParaflowView
            goals={goals}
            deps={dependencies}
            onTool={handleGoalTool}
          />
        </Tab.Pane>
        <Tab.Pane eventKey="log">
          <ParaflowLogView
            timeline={timeline}
            wrapped
            onClickChild={onOpenChild}
            onTool={handleLogTool}
          />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
}
