import React, { useState } from "react";
import { AuthService } from "../../../services";

import "./forgot.password.scss";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    username: "",
  });

  const login = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!form.username) {
      alert("Type the username.");
      return;
    }

    AuthService.forgotPassword(form.username).then((result) => {
      navigate("/auth/recovery-password");
    });
  };

  return (
    <div className="forgotPasswordPage">
      <h2 className="title">Login</h2>
      <form onSubmit={login} className="form">
        <div>
          <label>Username</label>
          <input
            type="text"
            name="username"
            value={form.username}
            onChange={(event) =>
              setForm({ ...form, username: event.target.value })
            }
          />
        </div>
        <button type="submit">Recovery Password</button>
      </form>
    </div>
  );
};
