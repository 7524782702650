import { Handle, NodeProps, Position } from "reactflow";

import styles from "./ParanetGroupNode.module.scss";

import { ParanetGroupNodeData } from "../../../entities/paranet/ora/ParanetGroupNodeType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const ParanetGroupNode = ({ data }: NodeProps<ParanetGroupNodeData>) => {
  return (
    <div className={styles.node}>
      <Handle type="target" hidden={true} position={Position.Top} />
      <div className={styles.content}>
        <FontAwesomeIcon icon={faGlobe} />
        <span>{data.name}</span>
      </div>
      <Handle type="source" hidden={true} position={Position.Bottom} />
    </div>
  );
};

export default ParanetGroupNode;
