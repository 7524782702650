import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as database from "./database/database";
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import ActorDetail from "./routes/actorDetail/actorDetail";
import { useParanetConnectionsStore } from "./store/useParanetConnections.store";
import Conversations from "./routes/conversations/conversations";
import ConversationDetail from "./routes/conversationDetail/conversationDetail";
import ORADetail from "./routes/oraDetail/oraDetail";
import { TestdriveServer } from "./entities/paranet/ParanetServer";
import { ParanetConnection } from "./servers";
import { Login } from "./routes/auth/login/login";
import { ForgotPassword } from "./routes/auth/forgotPassword/forgot.password";
import { CognitoAuth } from "./routes/auth/cognito/cognito.auth";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const isElectron = !!window.versions;

// Electron does not work with browser router
const routerFactory = isElectron ? createHashRouter : createBrowserRouter;

const router = routerFactory(
  [
    {
      path: "/auth/cognito",
      element: <CognitoAuth />,
    },
    {
      path: "/auth/login",
      element: <Login />,
    },
    {
      path: "/auth/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "/actors",
          element: <App />,
        },
        {
          path: "/actors/:actorId",
          element: <ActorDetail />,
        },
        {
          path: "/actors/:actorId/:action",
          element: <Conversations />,
        },
        {
          path: "/actors/:actorId/:action/:conversationId",
          element: <ConversationDetail />,
        },
        {
          path: "/oras",
          element: <App />,
        },
        {
          path: "/oras/:oraId",
          element: <ORADetail />,
        },
      ],
    },
  ],
  { basename: "/" }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

async function setup() {
  await useParanetConnectionsStore.getState().initialize();

  if (isElectron) {
    const testdrive = new ParanetConnection(TestdriveServer);
    useParanetConnectionsStore.getState().add(testdrive);
  }
}

database
  .init()
  .then(setup)
  .then(() => {
    root.render(
      // <React.StrictMode>
      <RouterProvider router={router} />
      // </React.StrictMode>
    );
  })
  .catch(() => {
    root.render(
      <React.StrictMode>Unable to open Paracord database</React.StrictMode>
    );
  });
