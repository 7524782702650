import { CognitoLoginResult } from "../../entities";
import settings from "../../settings";
import { AuthClient } from "../clients/auth.client";

export class AuthRepository {
  public static async login(
    username: string,
    password: string
  ): Promise<{ accessToken: string; refreshToken: string; idToken: string }> {
    const data = { username, password };

    const url = settings.env.AUTH_SERVICE_URI + "/auth/forgotPassword";

    const res = await AuthClient.makeRequest(url, "POST", data);

    const accessToken: string = res.accessToken || "";
    const refreshToken: string = res.refreshToken || "";
    const idToken: string = res.idToken || "";

    return { accessToken, refreshToken, idToken };
  }

  public static async forgotPassword(username: string): Promise<boolean> {
    const data = { username };

    const url = settings.env.AUTH_SERVICE_URI + "/auth/forgotPassword";

    const res = await AuthClient.makeRequest(url, "POST", data);

    return res === true;
  }

  public static async recoveryPassword(
    username: string,
    code: string
  ): Promise<boolean> {
    const data = { username, code };

    const url = settings.env.AUTH_SERVICE_URI + "/auth/recoveryPassword";

    const res = await AuthClient.makeRequest(url, "POST", data);

    return res === true;
  }

  public static async getToken(
    code: string,
    redirectUri: string
  ): Promise<CognitoLoginResult> {
    const url =
      settings.env.AUTH_SERVICE_URI +
      `/auth/token/${code}?redirectUri=${redirectUri}`;

    const res = await AuthClient.makeRequest(url, "GET");

    return res;
  }
}
