import { ActorDB, Actor, ActorMetadata } from "../entities";
import { ParanetConnection } from "../servers";

export async function listActors(pn: ParanetConnection): Promise<ActorDB> {
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    "X-ACTOR-ID": pn.loginId!,
  };
  if (pn.server.name !== "local") headers.Authorization = `Bearer ${pn.token}`;
  const baseResult = await fetch(`${pn.server.url}/graphql`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query: `
      query {
        listBaseActors {
          id
          name
          kind
        }
      }`,
      variables: {},
    }),
  }).then((result) => result.json());

  if (baseResult.errors) throw new Error(baseResult.errors[0].message);

  const baseList = baseResult.data.listBaseActors as ActorMetadata[];
  const list: Actor[] = [];
  const actorPromises: Promise<any>[] = [];
  baseList.forEach((meta) =>
    actorPromises.push(fetchActor(pn, headers, meta.id))
  );
  const results = await Promise.allSettled(actorPromises);
  for (let i = 0; i < baseList.length; i++) {
    const promiseResult = results[i];
    const currMeta = baseList[i];
    if (promiseResult.status === "rejected") {
      console.error(
        `Could not load actor ${currMeta.name}`,
        promiseResult.reason
      );
      continue;
    }

    const actors = promiseResult.value.data.listActors.actors as Actor[];
    actors.map((actor) => list.push(actor));
  }

  const actors: ActorDB = {};
  list.forEach((actor) => {
    const [name, version] = actor.entityId.split("@");
    if (actors[name]) {
      actors[name].versions[version] = actor;
      if (version > actors[name].current) actors[name].current = version;
    } else {
      actors[name] = {
        current: version,
        versions: {
          [version]: actor,
        },
      };
    }
  });

  return actors;
}

const fetchActor = async (
  pn: ParanetConnection,
  headers: Record<string, string>,
  actorId: string
) => {
  return await fetch(`${pn.server.url}/graphql`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      query: `
      query actors($id: Id!) {
        listActors(id: $id) {
          actors {
            entityId
            metadata {
              id
              name
              kind      
            }
            modelKind
            skillSets {
              entityId
            }
          }
        }
      }`,
      variables: { id: actorId },
    }),
  })
    .then((response) => response.json())
    .catch((err) => console.log("listActors failed: " + err));
};
