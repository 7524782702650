import darkJson from "../microsoft-teams-dark.json";
import * as AdaptiveCards from "adaptivecards";

export function getCardData(inst: AdaptiveCards.AdaptiveCard) {
  const entries = inst
    .getAllInputs()
    .filter((inp) => !!inp.id)
    .map((inp) => [inp.id, inp.value]);
  return Object.fromEntries(entries);
}

function getBodyFontSize() {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const modalHostConfig = new AdaptiveCards.HostConfig(darkJson);
modalHostConfig.containerStyles.default.backgroundColor = "#ff212529";
modalHostConfig.fontTypes!.default.fontSizes.default = getBodyFontSize();

export const msgHostConfig = () => {
  const config = new AdaptiveCards.HostConfig(darkJson);
  config.spacing.padding = 4;
  config.containerStyles.default.backgroundColor = "#ff313338";
  config.fontTypes!.default.fontSizes.default = getBodyFontSize();

  return config;
};

export function createModalCard(): AdaptiveCards.AdaptiveCard {
  const card = new AdaptiveCards.AdaptiveCard();
  card.hostConfig = modalHostConfig;
  return card;
}
