import { Card } from "react-bootstrap";
import { useParanetContext } from "../../hooks/useParanetContext";
import { getMessageSummary } from "../../utils/message.util";
import {
  isThreadUnread,
  getThreadTitle,
  getThreadAge,
} from "../../utils/thread.utils";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { identifierToTitle } from "../../utils/utility";

import styles from "./conversations.module.scss";
import { useParams } from "react-router-dom";

const Conversations = () => {
  const { actorId, action } = useParams();
  const navigate = useNavigate();
  const { actor, threads, selectedParanet } = useParanetContext();

  if (!actor || !selectedParanet) {
    return;
  }

  const ActionName = () => {
    return action ? identifierToTitle(action) : "Action";
  };

  return (
    <>
      <h1 className={styles.title}>
        <div
          className={styles.btnBack}
          onClick={() => navigate(`/actors/${actor.entityId}`)}
        >
          <FontAwesomeIcon icon={faChevronCircleLeft} />
        </div>
        <ActionName />
      </h1>
      <div
        className="grid-view"
        style={{
          flex: 1,
          gap: "28px",
          display: "grid",
          gridTemplateRows: "repeat(auto-fill, 150px)",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
        }}
      >
        {threads
          ?.filter(
            (th) =>
              th.action === action &&
              (th.recipient?.actorEntityId === actorId ||
                th.initiator.actorEntityId === actorId)
          ) // TODO: improve this check
          .map((th, idx) => {
            const now = new Date();
            const m =
              th.messages && th.messages.length > 0
                ? th.messages[th.messages.length - 1]
                : undefined;
            const titleClass = isThreadUnread(selectedParanet.loginId!, th)
              ? "unread"
              : "read";
            return (
              <Card key={idx} className={styles.card}>
                <Card.Body
                  className={styles.cardBody}
                  onClick={() => navigate(`${th.id}`)}
                >
                  <Card.Title>
                    <span className={titleClass}>
                      {getThreadTitle(th, 100)}
                    </span>
                  </Card.Title>
                  <Card.Text className={styles.cardText}>
                    {m && getMessageSummary(th, m, selectedParanet.loginId!)}
                  </Card.Text>
                  <p className={styles.threadAge}>
                    <FontAwesomeIcon icon={faClock} /> {getThreadAge(now, th)}
                  </p>
                </Card.Body>
              </Card>
            );
          })}
      </div>
    </>
  );
};

export default Conversations;
