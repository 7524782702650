import {
  faGears,
  faPlusSquare,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./SettingsPopup.module.scss";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { ParanetConnection } from "../../../servers";
import { identifierToTitle } from "../../../utils/utility";
import { getMemberName } from "../../../utils/conversation.utils";

const classes = classNames.bind(styles);

interface SettingsPopupProps {
  activeParanet?: ParanetConnection;
  onRequestLogout: () => void;
  onRequestAddNewServer: () => void;
}

const SettingsPopup = ({
  activeParanet,
  onRequestAddNewServer,
  onRequestLogout,
}: SettingsPopupProps) => {
  const popupRef: RefObject<HTMLDivElement> = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target as HTMLElement) &&
      popupOpen
    ) {
      setPopupOpen(false);
    }
  };

  return (
    <>
      <div
        className={classes(styles.settings, { popupOpen })}
        onClick={() => setPopupOpen(true)}
      >
        <div className={styles.icon} title="Settings">
          <FontAwesomeIcon icon={faGears} color="#03396c" />
        </div>
      </div>
      <div ref={popupRef} className={styles.popup}>
        <small>
          Logged-in as <strong>{getMemberName(activeParanet?.loginId)}</strong>
        </small>
        <div
          className={styles.option}
          onClick={() => {
            onRequestAddNewServer();
            setPopupOpen(false);
          }}
        >
          <span className={styles.optionIcon}>
            <FontAwesomeIcon icon={faPlusSquare} color="#03396c" />
          </span>
          <span className={styles.optionLabel}>New Paranet Server</span>
        </div>
        {activeParanet && (
          <div
            className={styles.option}
            onClick={() => {
              onRequestLogout();
              setPopupOpen(false);
            }}
          >
            <span className={styles.optionIcon}>
              <FontAwesomeIcon icon={faSignOutAlt} color="#03396c" />
            </span>
            <span className={styles.optionLabel}>
              Disconnect from {identifierToTitle(activeParanet.server.name)}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingsPopup;
