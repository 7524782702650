import { faChevronRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { identifierToTitle, truncateSentence } from "../../utils/utility";
import { Actor } from "../../entities/paranet/actor/Actor";
import { getMemberName } from "../../utils/conversation.utils";
import { ParanetConnection } from "../../servers";
import { isThreadSelected, isThreadUnread } from "../../utils/thread.utils";
import { Thread, ThreadTree } from "../../entities/paranet/thread/Thread";

import styles from "./ParanetActorList.module.scss";
import classNames from "classnames/bind";
import { useLocation, useNavigate } from "react-router";

const classes = classNames.bind(styles);

interface ParanetActorListTypes {
  actors: Actor[];
  loadingActors?: boolean;
  threadTree: ThreadTree;
  actorsSelection: string | string[];
  selectedParanet?: ParanetConnection;
}

const ParanetActorList = ({
  actors,
  loadingActors,
  selectedParanet,
  actorsSelection,
  threadTree,
}: ParanetActorListTypes) => {
  const navigate = useNavigate();
  const location = useLocation();

  const groupThreads = (threads: Thread[]) => {
    if (!threads || threads.length <= 0) {
      return;
    }

    const groups: { id: string; threads: Thread[] }[] = [];
    threads.forEach((thread) => {
      const group = groups.find((g) => g.id === thread.action);
      if (group) {
        group.threads.push(thread);
      } else {
        groups.push({ id: thread.action || thread.id, threads: [thread] });
      }
    });

    return groups;
  };

  if (!selectedParanet) {
    return (
      <div className={styles.container}>
        Click on the cog icon and connect to a paranet to list actors
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {loadingActors ? (
        <div>Loading...</div>
      ) : (
        actors.map((actorObj) => {
          const actor = getMemberName(actorObj.entityId);
          const displayName =
            actorObj.metadata.name === actorObj.metadata.id
              ? identifierToTitle(actor)
              : actorObj.metadata.name;
          // rerender if count changes
          const groupKey = threadTree[actor]
            ? `${actor}:${threadTree[actor].length}`
            : actor;
          return (
            <div key={groupKey}>
              <div
                className={classes(styles.actor, {
                  selectedActor: location.pathname.endsWith(actorObj.entityId),
                })}
                onClick={() => navigate(`/actors/${actorObj.entityId}`)}
              >
                <span>
                  {displayName}
                  {actorObj.modelKind === "user" && (
                    <span>
                      {" "}
                      <FontAwesomeIcon icon={faUser} size="xs" />
                    </span>
                  )}
                </span>
                <div className={styles.rightAdornments}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              {groupThreads(threadTree[actor])
                //?.filter(isThreadVisibleInTree)
                ?.map((threadGroup) => {
                  let threadClass = "";
                  if (isThreadSelected(actorsSelection, actor, threadGroup.id))
                    threadClass = styles.selectedAction;
                  else {
                    threadClass = threadGroup.threads.some((th) =>
                      isThreadUnread(selectedParanet.loginId!, th)
                    )
                      ? "unread"
                      : "read";
                  }
                  return (
                    <div
                      key={threadGroup.id}
                      className={classes(styles.action, threadClass)}
                      onClick={() =>
                        navigate(
                          `/actors/${actorObj.entityId}/${threadGroup.id}`
                        )
                      }
                    >
                      <span>
                        {truncateSentence(
                          identifierToTitle(threadGroup.id),
                          2,
                          30
                        )}
                      </span>
                      <div className={styles.rightAdornments}>
                        {threadGroup.threads.length > 0 && (
                          <div className={styles.conversationCount}>
                            {threadGroup.threads.length}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })
      )}
    </div>
  );
};

export default ParanetActorList;
