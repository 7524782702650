export type ParanetServer = {
  url: string;
  name: string;
  wsUrl: string;
  debug: boolean;
};

export const TestdriveServer: ParanetServer = {
  name: 'local',
  url: 'http://localhost:3132',
  wsUrl: 'ws://localhost:3131',
  debug: true
};