import {
  faArrowTurnDown,
  faChevronCircleLeft,
  faPlus,
  faReply,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getThreadTitle,
  markThreadAsRead,
  toThread,
} from "../../utils/thread.utils";

import styles from "./conversationDetail.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useParanetContext } from "../../hooks/useParanetContext";
import {
  getMemberName,
  isChat,
  isPncpResponse,
} from "../../utils/conversation.utils";
import { Button, Card, Modal } from "react-bootstrap";
import { plainStringify } from "../../utils/utility";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { SkillQnA, Skillset } from "../../entities/paranet/skillset/Skillset";
import { Conversation } from "../../entities/paranet/conversation/Conversation";
import { appDatabase } from "../../database/database";
import RjsfForm, { IChangeEvent } from "@rjsf/core";
import { Thread } from "../../entities/paranet/thread/Thread";
import { loadConversation } from "../../services/conversations.service";
import {
  Message,
  MessageData,
  RequestMessage,
  SkillMessageBody,
  SkillMessageRequest,
} from "../../entities/paranet/message/Message";
import { AdaptiveCard } from "../../adaptive-card";
import { MessageSchema } from "../../entities/schema/MessageSchema";
import { ParanetConnection } from "../../servers";
import { isEmptySchema, schemaProperties } from "../../utils/schema.utils";
import { PncpMessage } from "../../observations";
import { getDataSummary, removeNuls } from "../../utils/message.util";
import validator from "@rjsf/validator-ajv8";
import {
  createModalCard,
  getCardData,
  msgHostConfig,
} from "../../utils/adaptiveCards.utils";
import { toEntries } from "../../utils";
import { CardSchema, Entry, uiSchema } from "../../entities";

async function responseSchema(
  skill: SkillMessageRequest,
  pn: ParanetConnection
): Promise<MessageSchema> {
  // Get my skills
  const ss = await pn.skillsetDB.getSkillset(pn.loginId || "guest@1.0.0");
  const { subject, action } = skill.body;
  const skillDef = ss.skills.find((sk) => sk.subject === subject);
  if (skillDef) {
    const actionDef = skillDef.actions.find((act) => act.action === action);
    if (actionDef) {
      const { properties, required } = await schemaProperties(
        actionDef.outputRef,
        {}
      );
      return {
        title: "Response",
        type: "object",
        properties,
        required,
      };
    }
  }
  throw new Error(`Missing ${subject}/${action} skill definition`);
}

async function replySchema(qu: SkillQnA): Promise<MessageSchema> {
  if (qu.answerForm && qu.answerForm.type === "AdaptiveCard") {
    return qu.answerForm as CardSchema;
  }

  const { properties, required } = await schemaProperties(qu.answer, {});
  return {
    title: "Inputs",
    type: "object",
    properties,
    required,
  };
}

async function questionSchema(qu: SkillQnA): Promise<MessageSchema> {
  if (qu.questionForm && qu.questionForm.type === "AdaptiveCard") {
    return qu.questionForm as CardSchema;
  }

  const { properties, required } = await schemaProperties(qu.question, {});
  return {
    title: "Inputs",
    type: "object",
    properties,
    required,
  };
}

function autoParagraphs(s: string | undefined, prefix: string = "") {
  if (s) {
    const paragraphs = s.split("\n\n");
    if (paragraphs.length > 1) {
      return (
        <div>
          {prefix && <div>{prefix}</div>}
          {paragraphs.map((p) => linkify(p))}
        </div>
      );
    }
    return linkify(s, prefix);
  }
  return "";
}

interface MessageContext {
  thread: Thread;
  request?: Message;
  response?: Message;
  skillsets: Record<string, Skillset>;
}

function replyQuote(m: Message) {
  const prefix = [
    <FontAwesomeIcon icon={faArrowTurnDown} flip="horizontal" key="pref1" />,
    <span key="pref2">&nbsp;</span>,
  ];
  if (m.contents.type === "SkillRequest") {
    return (
      <div className="reply-quote">
        {prefix}
        <span className="author">{getMemberName(m.senderEntityId)}</span>:{" "}
        {m.contents.data.request.body.action} request{" "}
        {m.contents.data.data?.data &&
          getDataSummary("with", m.contents.data.data.data)}
      </div>
    );
  }
  if (m.contents.type === "PncpMessage") {
    const msgData = m.contents.data.message.data;
    removeNuls(msgData);
    if (m.contents.data.message.message_type === "Response") {
      return (
        <div className="reply-quote">
          {prefix}
          <span className="author">{getMemberName(m.senderEntityId)}</span>:
          responded {getDataSummary("with", msgData)}
        </div>
      );
    }
    if (m.contents.data.message.message_type === "Question") {
      return (
        <div className="reply-quote">
          {prefix}
          <span className="author">
            {getMemberName(m.senderEntityId)}
          </span>: {m.contents.data.message.id}{" "}
          {getDataSummary("with", msgData)}
        </div>
      );
    }
    if (m.contents.data.message.message_type === "Answer") {
      return (
        <div className="reply-quote">
          {prefix}
          <span className="author">{getMemberName(m.senderEntityId)}</span>:
          answered {getDataSummary("with", msgData)}
        </div>
      );
    }
    return (
      <div className="reply-quote">
        {prefix}
        <span className="author">{getMemberName(m.senderEntityId)}</span>:{" "}
        {m.contents.data.message.message_type.toLowerCase()}{" "}
        {plainStringify(msgData)}
      </div>
    );
  }
  return <div className="reply-quote">{getMemberName(m.senderEntityId)}</div>;
}

function getConversationAction(
  req: Message,
  skillsets: Record<string, Skillset>
) {
  const contents = req.contents as SkillMessageBody;
  const skillsetId = contents.data.data?.skillset_id;
  if (skillsetId) {
    let ss;
    if (skillsets[skillsetId]) ss = skillsets[skillsetId];
    else if (skillsetId.startsWith("skill-")) {
      // Actor skills defined inline have this prefix
      ss = skillsets[skillsetId.substring(6)];
    }
    if (ss) {
      const { subject, action } = contents.data.request.body;
      const skill = ss.skills.find((sk) => sk.subject === subject);
      if (skill) return skill.actions.find((act) => act.action === action);
    }
  }
}
// Test if a word contains a URL after removing punctuation

const urlPattern = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/m;
const endPunct = /[.,?!;]$/;
const openChars = /^["([{<]+/;
const closeChars = /[")\]}>]+$/;

function matchUrl(word: string) {
  const clean = word.replace(endPunct, "");
  const open = openChars.exec(clean);
  if (open) {
    const close = closeChars.exec(clean);
    if (close) {
      const prefix = open[0];
      const suffix = close[0] + word.substring(clean.length);
      const mid = word.substring(prefix.length, word.length - suffix.length);
      if (urlPattern.test(mid)) return [prefix, mid, suffix];
    }
  } else if (urlPattern.test(clean)) {
    return ["", clean, word.substring(clean.length)];
  }
}

function linkify(text: string, prefix: string = "") {
  const words = text.split(" ");
  const elements: JSX.Element[] = [];
  if (prefix) elements.push(<span>{prefix}</span>);
  let start = 0;
  for (let idx = 0; idx < words.length; idx++) {
    const w = words[idx];
    const match = matchUrl(w);
    if (match) {
      if (idx > start) {
        elements.push(<span>{words.slice(start, idx).join(" ")} </span>);
      }
      const [urlPrefix, url, urlSuffix] = match;
      if (urlPrefix) elements.push(<span>{urlPrefix}</span>);
      elements.push(
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      );
      if (urlSuffix) elements.push(<span>{urlSuffix}</span>);
      start = idx + 1;
    }
  }
  if (elements.length == 0) return <div>{text}</div>;

  if (start < words.length) {
    elements.push(<span> {words.slice(start).join(" ")}</span>);
  }

  return <div>{elements}</div>;
}

function isSingleField(obj: Record<string, unknown>, field: string) {
  if (Object.hasOwn(obj, field)) return Object.keys(obj).length == 1;
  return false;
}

function getMessageTime(now: Date, m: Message) {
  const t = new Date(m.createdAt);
  if (
    now.getDate() == t.getDate() &&
    now.getMonth() == t.getMonth() &&
    now.getFullYear() == t.getFullYear()
  ) {
    return `Today at ${t.toTimeString().slice(0, 8)}`;
  }
  if (
    now.getDate() - 1 == t.getDate() &&
    now.getMonth() == t.getMonth() &&
    now.getFullYear() == t.getFullYear()
  ) {
    return `Yesterday at ${t.toTimeString().slice(0, 8)}`;
  }
  return t.toString();
}

function formatDataMessage(msgData: MessageData, prefix?: string) {
  let sep = "";
  let body: string | MessageData = msgData;

  if (isSingleField(msgData, "result")) {
    body = msgData.result || "";
  } else if (isSingleField(msgData, "message")) {
    body = msgData.message;
  }

  // TODO - maybe special cases based on element type?
  if (Array.isArray(body)) body = JSON.stringify(body);

  if (typeof body === "object") {
    sep = " - ";
    body = getDataSummary("", body);
  } else {
    sep = ": ";
  }
  if (prefix) {
    prefix += sep;
    if (!body) body = "<empty>";
  }

  return <div>{autoParagraphs(body, prefix)}</div>;
}

function formatMessage(
  myId: string,
  m: Entry,
  context: MessageContext,
  reply: (conv: Conversation, m: Message, q?: SkillQnA) => void
) {
  if (m.contents.type === "SkillRequest") {
    const { request } = m.contents.data;
    if (isChat(context.thread)) {
      if (request.body.data.message)
        return <div>{request.body.data.message}</div>;
    }
    let display = linkify(
      `Request - ${request.body.subject}: ${request.body.action} ${
        m.contents.data.data?.data &&
        getDataSummary("with", m.contents.data.data.data)
      }`
    );

    const actionData = m.contents.data.data;
    const inputSchemaRef = actionData?.input_schema_ref;
    if (inputSchemaRef && actionData) {
      for (const [key, value] of Object.entries(inputSchemaRef)) {
        if (value !== "paranet:document_ref") continue;
        const imageContent =
          key === "image" && actionData.data[key]?.imageContent;

        if (imageContent) {
          const imageUrl = URL.createObjectURL(imageContent);
          display = <img src={imageUrl} />;
        }
      }
    }

    if (
      context.thread.recipient &&
      context.thread.recipient.actorEntityId === myId /* && !context.response */
    ) {
      // Unanswered request to me
      return (
        <div>
          {display}
          <div
            className="pt-2"
            style={{ cursor: "pointer" }}
            onClick={() => reply(context.thread, m)}
          >
            <FontAwesomeIcon icon={faReply} /> Reply
          </div>
        </div>
      );
    }
    return display;
  }
  if (m.contents.type === "PncpMessage") {
    const msgData = m.contents.data.message.data;
    removeNuls(msgData);
    if (m.contents.data.message.message_type === "Response") {
      if (context.request) {
        const action = getConversationAction(
          context.request,
          context.skillsets
        );
        if (action && action.outputDisplay) {
          const msgContext = {
            $root: msgData,
          };
          return (
            <AdaptiveCard
              payload={action.outputDisplay as CardSchema}
              context={msgContext}
              hostConfig={msgHostConfig()}
            />
          );
        }
      }
      return <div>Response -{getDataSummary("with", msgData)}</div>;
    }
    if (m.contents.data.message.message_type === "Question") {
      const qId = m.contents.data.message.id;
      const desc = isSingleField(msgData, "question")
        ? `: ${msgData.question}`
        : getDataSummary(" with", msgData);
      if (context.request) {
        const action = getConversationAction(
          context.request,
          context.skillsets
        );
        if (action) {
          const question = (
            m.senderEntityId === myId
              ? action.requesterQuestions
              : action.fulfillerQuestions
          ).find((q) => q.id === qId);
          if (question) {
            if (m.senderEntityId !== myId) {
              // Question to me
              return (
                <div>
                  {question.name} question
                  {desc}
                  {!m.answered && (
                    <div
                      className="pt-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => reply(context.thread, m, question)}
                    >
                      <FontAwesomeIcon icon={faReply} /> Reply
                    </div>
                  )}
                </div>
              );
            }
            // Question from me
            if (question.questionDisplay) {
              const msgContext = {
                $root: msgData,
              };
              return (
                <AdaptiveCard
                  payload={question.questionDisplay as CardSchema}
                  context={msgContext}
                  hostConfig={msgHostConfig()}
                />
              );
            }
          }
        }
      }

      return (
        <div>
          Question - {qId}
          {desc}
        </div>
      );
    }
    if (m.contents.data.message.message_type === "Answer") {
      if (context.request && m.question) {
        const pncpMsg = m.question.contents.data as PncpMessage;
        const qId = pncpMsg.message.id;
        const action = getConversationAction(
          context.request,
          context.skillsets
        );
        if (action) {
          const question = (
            m.question.senderEntityId === myId
              ? action.requesterQuestions
              : action.fulfillerQuestions
          ).find((q) => q.id === qId);
          if (question && question.answerDisplay) {
            const msgContext = {
              $root: msgData,
            };
            return (
              <AdaptiveCard
                payload={question.answerDisplay as CardSchema}
                context={msgContext}
                hostConfig={msgHostConfig()}
              />
            );
          }
        }
      }
      return formatDataMessage(msgData);
    }
    if (m.contents.data.message.message_type === "Status") {
      if (isChat(context.thread)) {
        if (msgData.message) return <div>{msgData.message}</div>;
      }
      return formatDataMessage(msgData, "Status");
    }
    return (
      <div>
        {m.contents.data.message.message_type.toLowerCase()}{" "}
        {plainStringify(m.contents.data.message.data)}
      </div>
    );
  }
  return <p />;
}

function gatherQuestions(
  conv: Conversation,
  skillsets: Record<string, Skillset>
) {
  const req = conv.messages?.find((m) => m.contents.type === "SkillRequest");
  if (req) {
    const action = getConversationAction(req, skillsets);
    if (action) return action.requesterQuestions;
  }
  return [];
}

interface ActiveReply {
  conv: Conversation;
  m: Message;
  qu?: SkillQnA;
  schema: MessageSchema;
}

interface ActiveQuestion {
  conv: Conversation;
  qu: SkillQnA;
  schema: MessageSchema;
}

const ConversationDetail = () => {
  const modalCardInst = createModalCard();

  const navigate = useNavigate();
  const replyBox = useRef<HTMLInputElement>(null);
  const { actorId, conversationId } = useParams();
  const { actor, threads, selectedParanet } = useParanetContext();

  const [thread, setThread] = useState<Thread | undefined>(
    threads?.find(
      (th) =>
        th.id === conversationId &&
        (th.recipient?.actorEntityId === actorId ||
          th.initiator.actorEntityId === actorId)
    )
  );
  const [entries, setEntries] = useState<Entry[]>([]);
  const [skillsets, setSkillsets] = useState<Record<string, Skillset>>({});
  const [modalFormData, setModalFormData] = useState<Record<
    string,
    string
  > | null>(null);

  const [activeQuestion, setActiveQuestion] = useState<ActiveQuestion | null>(
    null
  );
  const [activeReply, setActiveReply] = useState<ActiveReply | null>(null);

  useEffect(() => {
    void fetchThreadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actor, selectedParanet, threads, conversationId]);

  useEffect(() => {
    void fetchSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actor, selectedParanet]);

  useEffect(() => {
    const activeThread = threads?.find(
      (th) =>
        th.id === conversationId &&
        (th.recipient?.actorEntityId === actorId ||
          th.initiator.actorEntityId === actorId)
    );

    setThread(activeThread);
  }, [threads, conversationId, actorId]);

  const fetchThreadMessages = async () => {
    if (!selectedParanet || !conversationId) {
      return;
    }

    const myId = selectedParanet.loginId || "guest@1.0.0";
    const fetchMessages = async () => {
      const conv = await loadConversation(selectedParanet, conversationId);
      const state = await appDatabase.getThreadState(conv.id);
      const threadDetails = toThread(conv, state);
      markThreadAsRead(myId, threadDetails); // update with count and lastUpdated
      setThread(threadDetails);
      void fetchDocumentsFromMessages(threadDetails.messages || []);
    };

    if (thread?.messages) {
      void fetchDocumentsFromMessages(thread.messages);
      markThreadAsRead(myId, thread);
    } else {
      setEntries([]);
      void fetchMessages().catch(console.error);
    }

    if (replyBox.current) {
      replyBox.current.value = "";
    }
  };

  const fetchDocumentsFromMessages = async (messages: Message[]) => {
    if (!selectedParanet) {
      return;
    }

    const requestedEntries = toEntries(messages);
    for (const entry of requestedEntries) {
      const actionData = (entry.contents.data as RequestMessage).data;
      const inputSchemaRef = actionData?.input_schema_ref;
      if (!inputSchemaRef || !actionData) {
        continue;
      }

      for (const [key, value] of Object.entries(inputSchemaRef)) {
        if (value !== "paranet:document_ref" || !actionData.data) {
          continue;
        }

        const messageDataProp = (actionData.data as MessageData)[
          key as keyof MessageData
        ];
        if (
          messageDataProp &&
          typeof messageDataProp !== "string" &&
          messageDataProp.id
        ) {
          const imageId = messageDataProp.id;

          const url = selectedParanet.server.url + "/document/fetch/" + imageId;

          const serverName = selectedParanet.server.name;
          const localLogins = await appDatabase.getLogin(serverName);

          const config = {
            headers: {
              authorization: `Bearer ${localLogins?.token}`,
            },
          };

          const response = await fetch(url, config);
          const imageContent = await response.blob();
          if (key === "image") {
            messageDataProp.imageContent = imageContent;
          }
        }
      }
    }
    setEntries(requestedEntries);
  };

  const fetchSkills = async () => {
    if (!actor || !selectedParanet) {
      return;
    }

    const skillsetPromises: Promise<Skillset>[] = [];
    actor.skillSets.forEach((ss) =>
      skillsetPromises.push(selectedParanet.skillsetDB.getSkillset(ss.entityId))
    );
    const results = await Promise.allSettled(skillsetPromises);
    for (let i = 0; i < actor.skillSets.length; i++) {
      const promiseResult = results[i];
      const currSkillSet = actor.skillSets[i];
      if (promiseResult.status === "rejected") {
        continue;
      }

      skillsets[currSkillSet.entityId] = promiseResult.value;
    }

    setSkillsets({ ...skillsets });
  };

  const newQuestion = (conv: Conversation, qu: SkillQnA) => {
    questionSchema(qu).then((schema) => {
      setModalFormData(null);
      setActiveQuestion({ conv, qu, schema });
    });
  };

  const messagingReplyKey = (
    e: KeyboardEvent<HTMLInputElement>,
    conv: Conversation
  ) => {
    if (e.key == "Enter" && e.currentTarget instanceof HTMLInputElement) {
      selectedParanet!.sendStatus(conv, {
        message: e.currentTarget.value,
      });
      e.currentTarget.value = "";
    }
  };

  const onSubmitQuestion = () => {
    if (activeQuestion) {
      const data =
        activeQuestion.schema.type === "AdaptiveCard"
          ? getCardData(modalCardInst)
          : modalFormData;
      console.log("sending", data);
      selectedParanet!
        .sendQuestion(activeQuestion.conv, activeQuestion.qu, data)
        .then(() => {
          setActiveQuestion(null);
        });
    }
  };

  const onSubmitReply = () => {
    if (activeReply) {
      const data =
        activeReply.schema.type === "AdaptiveCard"
          ? getCardData(modalCardInst)
          : modalFormData;
      console.log("sending", data);
      selectedParanet!
        .sendReply(activeReply.conv, activeReply.m, data)
        .then(() => {
          setActiveReply(null);
        });
    }
  };

  // Handles reply to both skill requests and questions
  const onReply = (conv: Conversation, m: Message, qu?: SkillQnA) => {
    if (qu) {
      replySchema(qu)
        .then((schema) => {
          setModalFormData(null);
          setActiveReply({
            conv,
            m,
            qu,
            schema,
          });
        })
        .catch((err) => alert(err.message));
    } else if (m.contents.type === "SkillRequest") {
      responseSchema(m.contents.data.request, selectedParanet!)
        .then((schema) => {
          setModalFormData(null);
          setActiveReply({ conv, m, schema });
        })
        .catch((err) => alert(err.message));
    }
  };

  const ThreadEntries = () => {
    if (!thread) {
      return null;
    }

    const now = new Date();
    const request = thread.messages?.find(
      (m) => m.contents.type === "SkillRequest"
    );
    const response = thread.messages?.find(isPncpResponse);
    const convContext = {
      thread,
      request,
      response,
      skillsets,
    };
    return entries.map((m, idx) => (
      <Card key={idx} className="mt-2">
        <Card.Body>
          <Card.Text>
            {m.question && replyQuote(m.question)}
            <div>
              <span className="author">{getMemberName(m.senderEntityId)}</span>{" "}
              <span className="date">{getMessageTime(now, m)}</span>
            </div>
            {formatMessage(selectedParanet!.loginId!, m, convContext, onReply)}
          </Card.Text>
        </Card.Body>
      </Card>
    ));
  };

  const onChangeFormData = (data: IChangeEvent, _id?: string) => {
    const { formData } = data;
    setModalFormData(formData);
  };

  if (!thread) {
    return <div style={{ color: "#000" }}>Loading conversation...</div>;
  }

  return (
    <>
      <h1 className={styles.title}>
        <div
          className={styles.btnBack}
          onClick={() => navigate(`/actors/${actorId}/${thread.action}`)}
        >
          <FontAwesomeIcon icon={faChevronCircleLeft} />
        </div>
        {getThreadTitle(thread, 100)}
      </h1>
      <div>
        <ThreadEntries />

        {isChat(thread) && (
          <Card className="mt-2">
            <Card.Body>
              <Card.Text>
                <div className="form-group">
                  <input
                    ref={replyBox}
                    type="text"
                    className="form-control"
                    id="messageReply"
                    onKeyDown={(e) => messagingReplyKey(e, thread)}
                  />
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        )}
        {gatherQuestions(thread, skillsets).map((qu, idx) => (
          <div
            key={idx}
            className="p-2 question"
            style={{ cursor: "pointer" }}
            onClick={() => newQuestion(thread, qu)}
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            {qu.name}
          </div>
        ))}
      </div>
      {activeReply && (
        <Modal
          size="lg"
          show={!!activeReply}
          onHide={() => setActiveReply(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {activeReply.qu && <span>{activeReply.qu.name} reply</span>}
              {!activeReply.qu && <span>Response</span>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!isEmptySchema(activeReply.schema) &&
              activeReply.schema.type === "object" && (
                <RjsfForm
                  schema={activeReply.schema}
                  uiSchema={uiSchema}
                  validator={validator}
                  formData={modalFormData}
                  onChange={onChangeFormData}
                />
              )}
            {activeReply.schema.type === "AdaptiveCard" && (
              <AdaptiveCard card={modalCardInst} payload={activeReply.schema} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => onSubmitReply()}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {activeQuestion && (
        <Modal
          size="lg"
          show={!!activeQuestion}
          onHide={() => setActiveQuestion(null)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Send
              {activeQuestion.qu.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!isEmptySchema(activeQuestion.schema) &&
              activeQuestion.schema.type === "object" && (
                <RjsfForm
                  schema={activeQuestion.schema}
                  uiSchema={uiSchema}
                  validator={validator}
                  formData={modalFormData}
                  onChange={onChangeFormData}
                />
              )}
            {activeQuestion.schema.type === "AdaptiveCard" && (
              <AdaptiveCard
                card={modalCardInst}
                payload={activeQuestion.schema}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => onSubmitQuestion()}>
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ConversationDetail;
