import {
  faAnglesLeft,
  faAnglesRight,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import { Debugger } from "./Debugger";

interface DebugPanelTypes {
  count: number;
  debugOpen?: boolean;
  debugRefresh: number;
  actorsSelection: string | string[];
  onTogglePanel: (state: boolean) => void;
  onRefreshUpdate: (refresh: number) => void;
}

const DebugPanel = ({
  count,
  debugOpen,
  actorsSelection,
  debugRefresh,
  onTogglePanel,
  onRefreshUpdate,
}: DebugPanelTypes) => {
  return (
    <Col
      md={debugOpen ? "5" : "auto"}
      style={{ overflowY: "auto", height: "100%" }}
    >
      {!debugOpen && (
        <Row style={{ cursor: "pointer" }} onClick={() => onTogglePanel(true)}>
          <Col md="auto">
            <FontAwesomeIcon icon={faAnglesLeft} />
          </Col>
        </Row>
      )}
      {debugOpen && (
        <Row style={{ cursor: "pointer" }} onClick={() => onTogglePanel(false)}>
          <Col />
          <Col md="auto">
            <FontAwesomeIcon icon={faAnglesRight} />
          </Col>
        </Row>
      )}
      {debugOpen &&
        (!actorsSelection || typeof actorsSelection === "string") && (
          <Row className="p-2">Select a conversation</Row>
        )}
      {debugOpen &&
        actorsSelection &&
        typeof actorsSelection !== "string" && [
          <Row key="1">
            <Col />
            <Col
              md="1"
              style={{ cursor: "pointer" }}
              onClick={() => onRefreshUpdate(debugRefresh + 1)}
            >
              <FontAwesomeIcon icon={faRefresh} />
            </Col>
          </Row>,
          <Row key="2">
            <Col>
              <Debugger
                count={count}
                refresh={debugRefresh}
                cid={actorsSelection[1]}
                actorId={actorsSelection[0]}
              />
            </Col>
          </Row>,
        ]}
    </Col>
  );
};

export default DebugPanel;
