import * as AdaptiveCards from "adaptivecards";
import * as ACData from "adaptivecards-templating";

import { useEffect, useRef, useState } from "react";
import * as PropTypes from "prop-types";
import { HostConfig } from "adaptivecards";
import { CardSchema } from "./entities";
// import * as markdown from 'markdown-it';
export interface Props {
  card?: AdaptiveCards.AdaptiveCard;
  payload: CardSchema;
  context?: ACData.IEvaluationContext;
  onExecuteAction?: (action: AdaptiveCards.Action) => void;
  onActionSubmit?: (action: AdaptiveCards.Action) => void;
  onActionOpenUrl?: (action: AdaptiveCards.Action) => void;
  onActionShowCard?: (action: AdaptiveCards.Action) => void;
  onError?: (errors: unknown) => void;
  style?: object;
  hostConfig?: HostConfig;
}

const propTypes = {
  /** A card instance */
  card: PropTypes.object,
  /** The card schema.  It must comply with the card schema. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/create/cardschema) */
  payload: PropTypes.object.isRequired,
  /** Templating context data */
  context: PropTypes.object,
  /** Method that will be invoked anytime a card action is executed. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/display/implementingrenderer#actions) */
  onExecuteAction: PropTypes.func,
  /** Method that will be invoked when a Submit action is executed. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/display/implementingrenderer#actionsubmit) */
  onActionSubmit: PropTypes.func,
  /** Method that will be invoked when an Open Url action is executed. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/display/implementingrenderer#actionopenurl) */
  onActionOpenUrl: PropTypes.func,
  /** Method that will be invoked when a Show Card action is executed. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/display/implementingrenderer#actionshowcard) */
  onActionShowCard: PropTypes.func,
  /** Method that will be invoked if an error is thrown while trying to render a card. */
  onError: PropTypes.func,
  /** JSX styles that will be applied to the card conatiner */
  style: PropTypes.object,
  /** HostConfig. [More Info](https://docs.microsoft.com/en-us/adaptive-cards/rendering-cards/host-config) */
  hostConfig: PropTypes.object,
};

/*
const setUpMarkdownIt = () => {
    if (!AdaptiveCards.AdaptiveCard.onProcessMarkdown) {
        AdaptiveCards.AdaptiveCard.onProcessMarkdown = (text, result) => {
            result.outputHtml = new markdown.default().render(text);
            result.didProcess = true;
        };
    }
};

setUpMarkdownIt();
*/

function createCard(hostConfig?: HostConfig) {
  const card = new AdaptiveCards.AdaptiveCard();
  if (hostConfig) card.hostConfig = hostConfig;
  return card;
}

function expandCard(
  templatePayload: CardSchema,
  context: ACData.IEvaluationContext
) {
  const template = new ACData.Template(templatePayload);
  return template.expand(context);
}

export function AdaptiveCard({
  card,
  payload,
  context,
  onError,
  style,
  hostConfig,
}: Props) {
  const [error, setError] = useState<Error>();
  const targetRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef(card || createCard(hostConfig));
  const sourceRef = useRef(context ? expandCard(payload, context) : payload);

  useEffect(() => {
    if (!targetRef.current) return;

    try {
      cardRef.current.parse(sourceRef.current);
      const result = cardRef.current.render() as HTMLElement;
      const trustedHtml =
        typeof window === "undefined"
          ? ""
          : window.trustedTypes?.emptyHTML ?? "";
      targetRef.current.innerHTML = trustedHtml as string;
      targetRef.current.appendChild(result);
    } catch (cardRenderError) {
      if (onError) onError(cardRenderError);

      if (cardRenderError instanceof Error) setError(cardRenderError);
    }
  }, [payload, onError]);

  if (error) return <div style={{ color: "red" }}>{error.message}</div>;

  return <div style={style} ref={targetRef} />;
}

AdaptiveCard.propTypes = propTypes;
