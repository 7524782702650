if ((window as any).env === undefined) {
  (window as any).env = {};
}

const settings = {
  env: {
    AUTH_SERVICE_URI:
      (window as any).env.REACT_APP_AUTH_SERVICE_URI ??
      process.env.REACT_APP_AUTH_SERVICE_URI,
  },
  COGNITO_LOGIN_PAGE_URL:
    (window as any).env.REACT_APP_COGNITO_LOGIN_PAGE_URL ??
    process.env.REACT_APP_COGNITO_LOGIN_PAGE_URL,
  COGNITO_REDIRECT_URI:
    (window as any).env.REACT_APP_COGNITO_REDIRECT_URI ??
    process.env.REACT_APP_COGNITO_REDIRECT_URI,
};

export default settings;
