import React from "react";
import "./Loader.scss";

export default function Loader() {
  return (
    <div id="loader">
      {/* <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      /> */}
      <img
        src="https://media.tenor.com/JBgYqrobdxsAAAAi/loading.gif"
        width={100}
      />
    </div>
  );
}
