import {
  faHandPointUp,
  faPuzzlePiece,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RefObject, useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { ActorSelectionStrategy } from "../../../../entities/paranet/ora/ActorSelectionStrategy";

import styles from "./StrategySelectionPopup.module.scss";

const classes = classNames.bind(styles);

interface StrategySelectionPopupTypes {
  actorSelection?: string;
  initialStrategy: ActorSelectionStrategy;
  onStrategyChange: (strategy: ActorSelectionStrategy) => void;
}

const StrategySelectionPopup = ({
  actorSelection,
  initialStrategy,
  onStrategyChange,
}: StrategySelectionPopupTypes) => {
  const popupRef: RefObject<HTMLDivElement> = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target as HTMLElement) &&
      popupOpen
    ) {
      setPopupOpen(false);
    }
  };

  const StrategyIcon = () => {
    switch (initialStrategy) {
      case ActorSelectionStrategy.BestFit:
        return (
          <FontAwesomeIcon
            size="xs"
            icon={faPuzzlePiece}
            title="Actor Selection: Best Fit"
          />
        );
      case ActorSelectionStrategy.FirstAvailable:
        return (
          <FontAwesomeIcon
            size="xs"
            icon={faHandPointUp}
            title="Actor Selection: First Available"
          />
        );
      case ActorSelectionStrategy.SpecificActor:
      default:
        return (
          <FontAwesomeIcon
            size="xs"
            icon={faUser}
            title={`Actor Selection: ${actorSelection || "Actor (None)"}`}
          />
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <button
        onClick={() => setPopupOpen(true)}
        className={classes(styles.selectionButton, "nodrag", { popupOpen })}
      >
        <StrategyIcon />
      </button>
      <div ref={popupRef} className={styles.popup}>
        <small>Actor Selection Strategy</small>
        <div
          className={styles.option}
          onClick={() => {
            onStrategyChange(ActorSelectionStrategy.SpecificActor);
            setPopupOpen(false);
          }}
        >
          <span className={styles.optionIcon}>
            <FontAwesomeIcon icon={faUser} color="#03396c" />
          </span>
          <span className={styles.optionLabel}>Specific Actor</span>
        </div>
        <div
          className={styles.option}
          onClick={() => {
            onStrategyChange(ActorSelectionStrategy.BestFit);
            setPopupOpen(false);
          }}
        >
          <span className={styles.optionIcon}>
            <FontAwesomeIcon icon={faPuzzlePiece} color="#03396c" />
          </span>
          <span className={styles.optionLabel}>Best Fit</span>
        </div>
        <div
          className={styles.option}
          onClick={() => {
            onStrategyChange(ActorSelectionStrategy.FirstAvailable);
            setPopupOpen(false);
          }}
        >
          <span className={styles.optionIcon}>
            <FontAwesomeIcon icon={faHandPointUp} color="#03396c" />
          </span>
          <span className={styles.optionLabel}>First Available</span>
        </div>
      </div>
    </div>
  );
};

export default StrategySelectionPopup;
