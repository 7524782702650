import { Button, Modal } from "react-bootstrap";
import RjsfForm, { IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ParanetConnection } from "../../servers";
import { ServerState, loginSchema, uiSchema } from "../../entities";
import { ParanetService } from "../../services/paranet";
import { useParanetConnectionsStore } from "../../store/useParanetConnections.store";

interface LoginModalTypes {
  selectedParanet: ParanetConnection;
  setParanet: (paranet: ParanetConnection) => void;
  setServerState?: Dispatch<SetStateAction<ServerState>>;
}

const LoginModal = ({
  setServerState,
  selectedParanet,
  setParanet,
}: LoginModalTypes) => {
  const [modalFormData, setModalFormData] = useState<Record<
    string,
    string
  > | null>(null);
  const [busy, setBusy] = useState(false);
  const [requestError, setRequestError] = useState("");

  const [setParanetServer] = useParanetConnectionsStore((state) => [state.add]);

  useEffect(() => {
    setModalFormData({
      username: selectedParanet.loginId || "",
    });
  }, [selectedParanet]);

  const onChangeFormData = (data: IChangeEvent, _id?: string) => {
    const { formData } = data;
    setModalFormData(formData);
  };

  const doLogin = () => {
    if (!modalFormData) {
      console.error("invalid form");
      return;
    }

    const username = modalFormData["username"] || "";
    const password = modalFormData["password"] || "";

    setRequestError("");
    setBusy(true);
    ParanetService.login(selectedParanet, username, password)
      .then((storedParanet) => {
        setParanetServer(storedParanet);
        // selectParanet(storedParanet.server.name);
        setParanet(storedParanet);
        setServerState?.("init");
      })
      .catch((error) => {
        console.log("paranet-login-error", error);
        setRequestError(error?.message || "Login failed!");
      })
      .finally(() => setBusy(false));
  };

  return (
    <Modal size="lg" show onHide={() => setServerState?.("login-failed")}>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RjsfForm
          schema={loginSchema}
          uiSchema={uiSchema}
          validator={validator}
          formData={modalFormData}
          onChange={onChangeFormData}
        />
      </Modal.Body>
      <Modal.Footer>
        {requestError}
        {busy && <FontAwesomeIcon icon={faRefresh} spin />}
        <Button variant="primary" onClick={() => doLogin()}>
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModal;
