import { GenericObjectType } from "@rjsf/utils";
import {
  MessageSchema,
  ObjectSchema,
  SchemaRef,
  SkillAction,
} from "../entities";
import { SchemaDB } from "../database/schemas";
import { CardSchema } from "../entities";

const isDocumentRef = (action: SkillAction): boolean => {
  if (action.inputRef?.type === "map") {
    for (const value of Object.values(action.inputRef?.body)) {
      if (value.name === "paranet:document_ref") return true;
    }
  }
  return false;
};

export async function requestSchema(
  action: SkillAction
): Promise<MessageSchema> {
  if (action.inputForm && action.inputForm.type === "AdaptiveCard") {
    return action.inputForm as CardSchema;
  }

  let properties = {};
  let required: string[] = [];

  const type = isDocumentRef(action) ? "documentRef" : "object";
  if (type === "object") {
    const schemaProps = await schemaProperties(action.inputRef, {});
    properties = schemaProps.properties;
    required = schemaProps.required;
  }

  return {
    title: "Inputs",
    type: type,
    properties,
    required,
  } as ObjectSchema;
}

export async function schemaProperties(
  schemaRef?: SchemaRef,
  defaults?: GenericObjectType
) {
  let properties: GenericObjectType = {};
  let required: string[] = [];
  if (schemaRef) {
    const schemaDB = new SchemaDB();
    if (schemaRef.type === "value") {
      const s = await schemaDB.getSchema(schemaRef.body.name);
      if (s.properties) {
        properties = s.properties;
      }
      if (s.required && !schemaRef.body.optional) {
        required = s.required;
      }
    } else if (schemaRef.type === "map") {
      const { body } = schemaRef;
      for (const key in body) {
        properties[key] = schemaDB.stripSchema(
          await schemaDB.getSchema(body[key].name)
        );
        if (!body[key].optional) {
          required.push(key);
        }
        if (defaults && defaults[key]) {
          properties[key].default = defaults[key];
        }
      }
    }
  }
  console.log(properties);
  return { properties, required };
}

export function isEmptySchema(schema: GenericObjectType) {
  return (
    typeof schema.properties !== "object" ||
    Object.keys(schema.properties).length == 0
  );
}
